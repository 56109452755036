import { combineReducers } from "@reduxjs/toolkit"
import loginReducer, { logout } from "./slices/login/loginSlice"
import profileSlice from "./slices/studentSlices/basicInformation/profileSlice"
import acheivementsSlice from "./slices/studentSlices/acheivementAndCertificates/acheivementsSlice"
import EducationSlice from "./slices/studentSlices/Education/EducationSlice"
import ProjectSlice from "./slices/studentSlices/projects/ProjectSlice"
import experienceSlice from "./slices/studentSlices/workExperience/experienceSlice"

const appReducer = combineReducers({
  login: loginReducer,
  profile: profileSlice,
  acheivementsAndCertificates: acheivementsSlice,
  educations: EducationSlice,
  projects: ProjectSlice,
  experiences: experienceSlice,
})

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    console.log("rootReducer")
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer
