export const AcheivementForm = ({ formData, handleChange, handleSubmit, activeTab }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className='col-name'>
        <label>{activeTab === 'achievements' ? 'Achievement Name*' : 'Certification Name*'}</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className='input-fields-about'
          placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} name`}
        />
      </div>
      <div className='row-name'>
        <div className='col-name'>
          <label>{activeTab === 'achievements' ? 'Achievement Date*' : 'Certification Date*'}</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
            className='input-fields'
            placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} date`}
          />
        </div>
        <div className='col-name'>
          <label>{activeTab === 'achievements' ? 'Achievement Link*' : 'Certification Link*'}</label>
          <input
            type="text"
            name="link"
            value={formData.link}
            onChange={handleChange}
            required
            className='input-fields'
            placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} link`}
          />
        </div>
      </div>
      <div className='col-name mt-6'>
        <label>{activeTab === 'achievements' ? 'Achievement Description*' : 'Certification Description*'}</label>
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          className='input-fields-about'
          placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} description`}
        />
      </div>
      <button type="submit" className='update-details-btn'>
        {activeTab === 'achievements' ? 'Add Achievement' : 'Add Certification'}
      </button>
    </form>
  );
};