import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  UpdatePassword,
  ForgotPassword,
  Signup,
  Login,
  Home,
  Navbar,
  About,
  ContactUs,
  StudentProfile,
  NotFound,
  Spinner,
  EmpProfile,
  EmpProfileData,
  JobPostForm,
  PostedJob,
  EditPost,
  EditProfile,
  AppliedOpportunities,
  Opportunity,
  OpportunityView
} from './components/index';

import PrivateRoute from './Utils/PrivateRoute';

import { reload, setLoggedIn } from './store/slices/login/loginSlice'

import {
  getUserDetails,
  fetchAchievements,
  fetchCertifications,
  fetchEducationData,
  fetchProjectData,
  fetchWorkExpData
} from './store/index';

function App() {
  const { isLoggedIn } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const UserEmail = JSON.parse(localStorage.getItem('UserEmail'));
  const UserId = JSON.parse(localStorage.getItem('id'));
  const UserType = localStorage.getItem('UserType');
  const [loading, setLoading] = useState(true);

  // Define routes
  const routes = [
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/contact', element: <ContactUs /> },
    { path: '/login', element: <Login /> },
    { path: '/signup/:tab', element: <Signup /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/update-password/:id', element: <UpdatePassword /> },
    { path: '/job-listing/:id', element: <OpportunityView /> },
    { path: '/profile/:type/:ProfileUrl', element: <StudentProfile /> },
  ];

  // Define protected routes
  const privateRoutes = [
    { path: '/profile/recruiter/:ProfileUrl', element: <EmpProfile /> },
    { path: '/edit-profile', element: <EditProfile isLoggedIn={isLoggedIn} /> },
    { path: '/applied', element: <AppliedOpportunities /> },
    { path: '/jobpost', element: <JobPostForm /> },
    { path: '/edit-emp-profile', element: <EmpProfileData /> },
    { path: '/posted-job', element: <PostedJob /> },
    { path: '/opportunities', element: <Opportunity /> },
    { path: '/edit-post/:id', element: <EditPost /> },
  ];

  useEffect(() => {
    if (UserEmail && UserType) {
      dispatch(reload());
      const user = {
        id: UserId,
        type: UserType,
      };
      dispatch(getUserDetails(user))
        .then(async () => {
          dispatch(setLoggedIn(true));
          if (UserType === "1") {
            await dispatch(fetchAchievements(UserEmail)).unwrap();
            await dispatch(fetchCertifications(UserEmail)).unwrap();
            await dispatch(fetchEducationData(UserEmail)).unwrap();
            await dispatch(fetchProjectData(UserEmail)).unwrap();
            await dispatch(fetchWorkExpData(UserEmail)).unwrap();
          }
          setLoading(false);
        })
        .catch(() => {
          dispatch(setLoggedIn(false));
          setLoading(false);
        })
    }
    else {
      setLoading(false);
    }
  }, [UserEmail, UserId, UserType, dispatch]);

  return loading ? (<Spinner />) : (
    <div className="w-screen h-screen flex-col">
      <Navbar />

      <Routes>
        {/* Map over public routes */}
        <Route path='*' element={<NotFound />} />
        {routes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}

        {/* Map over private routes */}
        {privateRoutes.map(({ path, element }, index) => (
          <Route
            key={index}
            path={path}
            element={<PrivateRoute >{element}</PrivateRoute>}
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;

