import { useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const ContactForm = ({ loading, setLoading }) => {
  const API_PORT = process.env.REACT_APP_API_PORT;
  const { Email, First_name, Last_name } = useSelector((state) => state.profile.user);
  const fullName = First_name ? `${First_name} ${Last_name}` : '';

  const initialFormData = {
    name: fullName,
    email: Email || '',
    image: null,
    message: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("message", formData.message);
    if (formData.image) {
      data.append("file", formData.image);
    }

    try {
      await axios.post(`${API_PORT}/contact/send`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFormData(initialFormData);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      toast.success('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error.response?.data || error.message);
      toast.error('Failed to send message');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-contact-form">
      <span className="contact-circle contact-one"></span>
      <span className="contact-circle contact-two"></span>
      <form onSubmit={handleSubmit} className="contact-form">
        <h3 className="contact-title">Contact us</h3>
        <div className="contact-input-container">
          <input
            type="text"
            name="name"
            className="contact-input"
            placeholder='Username'
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-input-container">
          <input
            type="email"
            name="email"
            className="contact-input"
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-input-container">
          <input
            type="file"
            name="image"
            className="contact-input"
            ref={fileInputRef}
            onChange={handleChange}
          />
        </div>
        <div className="contact-input-container contact-textarea">
          <textarea
            name="message"
            className="contact-input"
            placeholder='Message'
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className={`contact-btn ${loading ? "disabled" : ""}`}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
