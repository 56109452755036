import React from 'react';
import { deleteProject } from '../../../store';
import { useDispatch } from 'react-redux';
import { MdDeleteForever } from 'react-icons/md';

const ProjectCard = (project) => {
  const dispatch = useDispatch()

  const handleDelete = async (projectId) => {
    dispatch(deleteProject({ projectId }))
  };
  return (
    <div>
      {project.map((project, index) => (
        <div key={index} className='experience-item'>
          <div className='delete-icon' onClick={() => handleDelete(project.ProjectID, index)}>
            <MdDeleteForever size={20} />
          </div>
          <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Title : {project.Title}</p>
          <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{project.Description}</p>
          <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>{project.ProjectLink}</p>
        </div>
      ))}
    </div>
  );
};

export default ProjectCard;