import React, { useState } from 'react';
import axios from 'axios';
import { AcheivementForm } from './AcheivementForm';
import { useDispatch, useSelector } from 'react-redux'
import { fetchAchievements, fetchCertifications } from "../../../store/index"
import AcheivementCard from './AcheivementCard';
import toast from 'react-hot-toast';

const API_PORT = process.env.REACT_APP_API_PORT;

const Achievements = () => {
  const dispatch = useDispatch()
  const { Email } = useSelector((state) => state.profile.user);
  const { achievements, certificates } = useSelector((state) => state.acheivementsAndCertificates)

  // console.log(acheivements);
  const [achievementsData, setAchievementsData] = useState({
    name: '',
    date: '',
    link: '',
    description: '',
  });

  const [certificationsData, setCertificationsData] = useState({
    name: '',
    date: '',
    link: '',
    description: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('achievements');

  const handleChange = (e, setData) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_PORT}/add-${activeTab}`, {
        ...data,
        email: Email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        toast.success(response.data.message);
        setFormVisible(false);
        if (activeTab === "achievements") {
          dispatch(fetchAchievements(Email));
        }
        else if (activeTab === "certifications") {
          dispatch(fetchCertifications(Email));
        }
      } else {
        console.error(`Failed to add ${activeTab}:`, response.data.message);
      }
    } catch (error) {
      console.error(`Error adding ${activeTab}:`, error);
    }
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };


  return (
    <section className='box'>
      <p className="heading">Achievements & Certifications</p>
      <div className="account-type-container mb-8 mt-4">
        <button
          onClick={() => setActiveTab('achievements')}
          className={`button ${activeTab === 'achievements' ? 'button-student-active' : 'button-student-inactive'}`}
        >
          Achievements
        </button>
        <button
          onClick={() => setActiveTab('certifications')}
          className={`button ${activeTab === 'certifications' ? 'button-student-active' : 'button-student-inactive'}`}
        >
          Certifications
        </button>
      </div>

      <div>
        {activeTab === 'achievements' && AcheivementCard(achievements, activeTab)}
        {activeTab === 'certifications' && AcheivementCard(certificates, activeTab)}
      </div>

      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New {activeTab === 'achievements' ? 'Achievement' : 'Certification'}
        </div>
      )}

      {isFormVisible && (
        <AcheivementForm
          formData={activeTab === 'achievements' ? achievementsData : certificationsData}
          handleChange={(e) => handleChange(e, activeTab === 'achievements' ? setAchievementsData : setCertificationsData)}
          handleSubmit={(e) => handleSubmit(
            e,
            activeTab === 'achievements' ? achievementsData : certificationsData
          )}
          activeTab={activeTab}
        />
      )}
    </section>
  );
};

export default Achievements;
