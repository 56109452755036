import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { Link } from 'react-router-dom';

const ApplicantModal = ({ applicants, onClose }) => {
  const modalRef = useRef();
  const [isClosing, setIsClosing] = useState(false);
  const API_PORT = process.env.REACT_APP_API_PORT;
  const [applicantsData, setApplicantsData] = useState(applicants);
  const [filterStatus, setFilterStatus] = useState('All');

  function formatDate(date) {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return format(new Date(date), 'dd-MM-yyyy');
  }

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      modalRef.current.classList.add('opacity-100', 'scale-100');
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  const handleStatusChange = async (e, jobId) => {
    const updatedStatus = e.target.value;

    try {
      const response = await fetch(`${API_PORT}/update-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: jobId, status: updatedStatus }),
      });

      if (response.ok) {
        setApplicantsData((prevApplicants) =>
          prevApplicants.map((applicant) =>
            applicant.id === jobId
              ? { ...applicant, status: updatedStatus }
              : applicant
          )
        );
        console.log('Status updated successfully');
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // Handle filter change and reorder applicants
  const handleFilterChange = (e) => {
    const selectedStatus = e.target.value;
    setFilterStatus(selectedStatus);

    if (selectedStatus === 'All') {
      setApplicantsData(applicants);
    } else {
      const filteredApplicants = [...applicantsData].sort((a, b) => {
        if (a.status === selectedStatus && b.status !== selectedStatus) return -1;
        if (a.status !== selectedStatus && b.status === selectedStatus) return 1;
        return 0;
      });
      setApplicantsData(filteredApplicants);
    }
  };

  return (
    <div
      ref={modalRef}
      onClick={closeModal}
      className={`fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-center opacity-0 transition-opacity duration-300`}>
      <div className={`bg-orange-500 rounded-xl relative flex flex-col mt-10 gap-5 text-white transition-transform transform scale-95 duration-300 h-[90%] max-w-[100%] max-h-[90%] overflow-y-auto overflow-x-hidden  ${isClosing ? 'scale-0' : 'scale-100'}`}>
        <h1 className='pt-4 text-2xl md:text-3xl font-extrabold text-center'>Applicants Details</h1>
        <button className='absolute right-5 top-5' onClick={handleClose}>
          <RxCross2 size={30} />
        </button>

        {/* Filter Dropdown */}
        <div className="flex justify-between items-center px-6 py-2">
          <label htmlFor="statusFilter" className="text-lg font-medium">Filter by Status: </label>
          <select
            id="statusFilter"
            value={filterStatus}
            onChange={handleFilterChange}
            className="px-2 py-1 border rounded-lg text-black"
          >
            <option value="All">All</option>
            <option value="pending">Pending</option>
            <option value="accepted">Accepted</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>

        {/* Applicants Table */}
        <div className='px-6 py-4 md:px-20 md:py-10 flex flex-col gap-5 items-center mx-4'>
          <div className="overflow-x-auto w-full">
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b font-medium border-neutral-500">
                <tr>
                  <th scope="col" className="px-4 md:px-2 py-2 md:py-4">#</th>
                  <th scope="col" className="px-4 md:px-2 py-2 md:py-4">Name</th>
                  <th scope="col" className="px-4 md:px-2 py-2 md:py-4">Email</th>
                  <th scope="col" className="px-4 md:px-2 py-2 md:py-4">Apply Data</th>
                  <th scope="col" className="px-4 md:px-2 py-2 md:py-4">Resume</th>
                  <th scope="col" className="px-4 md:px-2 py-2 md:py-4">Status</th>
                </tr>
              </thead>
              <tbody>
                {applicantsData.map((data, index) => (
                  <tr key={index} className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap px-4 md:px-2 py-2 md:py-4 font-medium">{index + 1}</td>
                    <td className="whitespace-nowrap px-4 md:px-2 py-2 md:py-4">
                      <Link to={data.userProfile} target="_blank" className='text-white'>
                        {data.userName}
                      </Link>
                    </td>
                    <td className="whitespace-nowrap px-4 md:px-2 py-2 md:py-4">{data.userEmail}</td>
                    <td className="whitespace-nowrap px-4 md:px-2 py-2 md:py-4">{formatDate(data.appliedAt)}</td>
                    <td className="whitespace-nowrap px-4 md:px-2 py-2 md:py-4">
                      <Link to={data.resumeLink} target="_blank" className='text-white'> View Resume </Link>
                    </td>
                    <td className="whitespace-nowrap px-4 md:px-2 py-2 md:py-4">
                      <select
                        value={data.status}
                        onChange={(e) => handleStatusChange(e, data.id)}
                        className={`px-2 py-1 rounded text-black ${data.status === 'pending' ? 'bg-[#FBFF00]' : data.status === 'accepted' ? 'bg-[#49FF00]' : 'bg-[#FF0000]'}`}
                      >
                        <option value="pending" className='bg-[#FBFF00]'>Pending</option>
                        <option value="accepted" className='bg-[#49FF00]'>Accepted</option>
                        <option value="rejected" className='bg-[#FF0000]'>Rejected</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantModal;