import React from 'react';
import { FaMapMarkerAlt, FaBriefcase, FaCalendarAlt, FaCalendarCheck, FaToolbox, FaBuilding, FaLightbulb, FaDollarSign } from 'react-icons/fa';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';

const capitalizeWords = (text) => {
  if (!text) return 'N/A';
  return text
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

function opportunityCard({ job }) {
  // console.log(job);
  const id = job?.id;
  const formatDate = (isoDate) => {
    if (!isoDate) return '';
    return format(new Date(isoDate), 'MMM dd, yyyy');
  };

  const OpportunityIcon = job.opportunity_type === 'job' ? FaBuilding : FaLightbulb;

  const isNew = (postedDate) => {
    const daysSincePosted = Math.ceil((new Date() - new Date(postedDate)) / (1000 * 60 * 60 * 24));
    return daysSincePosted <= 7;
  };

  return (
    <Link to={`/job-listing/${id}`} className='no-underline text-black'>
      <div
        className="relative flex flex-col bg-white shadow-md rounded-lg cursor-pointer mb-6 ml-2 transition-transform transform hover:scale-105 hover:shadow-lg border border-gray-300 w-full max-w-lg overflow-hidden "
      >
        <div className="relative flex items-center p-6  rounded-t-lg border-b ">
          {/* {(job.profile_picture || job.Image) && (
            <div className="relative h-20 w-20 rounded-full overflow-hidden border-2 border-gray-300 shadow-sm bg-gray-200">
              <img
                src={job.profile_picture || job.Image}
                alt="Profile"
                className="h-full w-full object-cover"
              />
            </div>
          )} */}

          <div className="ml-4">
            <h3 className="text-xl font-bold text-gray-800">{job.company_name || 'N/A'}</h3>
            <p className="text-sm text-gray-600">{job.profile || 'N/A'}</p>
          </div>

          {/* <div className='flex items-center'>
              <div
                className={`w-4 h-4 rounded-full ${job.status === 'pending' ? 'bg-[#FBFF00] shadow-[0px_0px_10px_3px_#FBFF00]' : job.status === 'accepted' ? 'bg-[#49FF00] shadow-[0px_0px_10px_3px_#49FF00]' : 'bg-[#FF0000] shadow-[0px_4px_10px_2px_#FF0000]'
                  }`}></div>
              <div className={`ml-2 text-sm text-gray-600`}>{job.status || 'N/A'}</div>
          </div> */}

          {/* <div className="ml-auto">
            {job.is_featured && (
              <span className="bg-gray-200 text-gray-700 text-xs font-semibold py-1 px-3 rounded-full mr-2">Featured</span>
            )}
            {isNew(job.created_at) && (
              <span className="bg-gray-300 text-gray-700 text-xs font-semibold py-1 px-3 rounded-full">New</span>
            )}
          </div> */}
        </div>

        <div className="relative flex flex-col p-4 bg-white rounded-b-lg z-10 space-y-3">
          <div className="flex items-center text-gray-700">
            <FaMapMarkerAlt className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-location" />
            <Tooltip id="tooltip-location" content="Location" place="top" type="dark" effect="solid" />
            <span className="text-sm">{capitalizeWords(job.city) || 'N/A'}</span>
          </div>

          <div className="flex items-center text-gray-700">
            <FaBriefcase className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-jobtype" />
            <Tooltip id="tooltip-jobtype" content="Job Type" place="top" type="dark" effect="solid" />
            <span className="text-sm">{capitalizeWords(job.job_type) || 'N/A'}</span>
          </div>

          <div className="flex items-center text-gray-700">
            <FaToolbox className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-skills" />
            <Tooltip id="tooltip-skills" content="Skills Required" place="top" type="dark" effect="solid" />
            <span className="text-sm">Skills: {capitalizeWords(job.skills_required) || 'N/A'}</span>
          </div>


          {job.opportunity_type === "job" && <div className="flex items-center text-gray-700">
            <FaDollarSign className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-salary" />
            <Tooltip id="tooltip-salary" content="Salary" place="top" type="dark" effect="solid" />
            <span className="text-sm">Salary: {job.salary_min && job.salary_max ? `${parseInt(job.salary_min)} - ${parseInt(job.salary_max)}` : "Not Disclosed"}</span>
          </div>}


          {job.opportunity_type === "internship" && (
            <div className="flex items-center text-gray-700">
              <FaDollarSign className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-stipend" />
              <Tooltip id="tooltip-stipend" content="Stipend" place="top" type="dark" effect="solid" />
              <span className="text-sm">Stipend: {job.stipend && job.stipend_max ? `${parseInt(job.stipend)} - ${parseInt(job.stipend_max)}` : "Not Disclosed"}</span>
            </div>
          )}

          <div className="flex items-center text-gray-700">
            <FaCalendarCheck className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-deadline" />
            <Tooltip id="tooltip-deadline" content="Last Date to Apply" place="top" type="dark" effect="solid" />
            <span className="text-sm">Last date: {formatDate(job.deadline_apply) || 'N/A'}</span>
          </div>

          <div className="flex items-center text-gray-700">
            <FaCalendarAlt className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-postedon" />
            <Tooltip id="tooltip-postedon" content="Posted On" place="top" type="dark" effect="solid" />
            <span className="text-sm">Posted: {formatDate(job.postedAt) || 'N/A'}</span>
          </div>

          <div className="flex items-center text-gray-700">
            <OpportunityIcon className="mr-2 text-gray-500 text-lg" data-tooltip-id="tooltip-opptype" />
            <Tooltip id="tooltip-opptype" content="Opportunity Type" place="top" type="dark" effect="solid" />
            <span className="text-sm">{capitalizeWords(job.opportunity_type) || 'N/A'}</span>
          </div>

          {/* <div className="flex justify-between mt-3 gap-4">
          <button
            className="bg-gray-700 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-gray-600 transition-colors duration-300"
          >
            Apply Now
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-gray-600 transition-colors duration-300"
          >
            Save for Later
          </button>
        </div> */}
        </div>
      </div>
    </Link>
  );
}

export default opportunityCard;
