import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { AllOpportunities, JobFilter } from '../../index';
import '../../../CSS/opportunity.css';

const API_PORT = process.env.REACT_APP_API_PORT;

function Opportunity() {
  const [cards, setCards] = useState([]); // State to hold combined job and internship data
  const [filteredCards, setFilteredCards] = useState([]); // Filtered cards state
  const [searchTerm, setSearchTerm] = useState(""); // Holds search input value
  const [locationTerm, setLocationTerm] = useState(""); // Holds location input value

  // Fetching jobs
  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${API_PORT}/find-jobs`);
      const jobsData = response.data.data || [];
      setCards((prevCards) => [...prevCards, ...jobsData]); // Appending jobs data to existing cards
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };

  // Fetching internships
  const fetchInternships = async () => {
    try {
      const response = await axios.get(`${API_PORT}/find-internships`);
      const internshipsData = response.data.data || [];
      setCards((prevCards) => [...prevCards, ...internshipsData]); // Appending internships data to existing cards
    } catch (error) {
      console.error("Error fetching internship data:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchInternships();
  }, []);

  const handleSearchAndFilter = useMemo(() => {
    return (selectedFilters = { remote: [], jobType: [], applyDate: [] }) => {
      const { remote, jobType, applyDate } = selectedFilters;

      const filtered = cards.filter((card) => {
        const jobTypeValue = card.job_type?.toLowerCase() || "";
        const profileValue = card.profile?.toLowerCase() || "";
        const companyNameValue = card.company_name?.toLowerCase() || "";
        const cityValue = card.city?.toLowerCase() || "";
        const postedDate = new Date(card.created_at).getTime();

        // Search term and location filters
        const matchesSearchTerm =
          searchTerm === "" ||
          profileValue.includes(searchTerm.toLowerCase()) ||
          companyNameValue.includes(searchTerm.toLowerCase());
        const matchesLocation =
          locationTerm === "" || cityValue.includes(locationTerm.toLowerCase());

        // Filter for job type, remote options, and apply date
        const matchesRemote = remote.length === 0 || remote.includes(jobTypeValue);
        const matchesJobType =
          jobType.length === 0 || jobType.includes(card.opportunity_type?.toLowerCase());
        const matchesApplyDate = applyDate.length === 0 || applyDate.some((dateRange) => {
          const now = new Date().getTime();
          const dayInMs = 86400000;
          if (dateRange === "past 24 hours") return now - postedDate <= dayInMs;
          if (dateRange === "past 1 week") return now - postedDate <= dayInMs * 7;
          if (dateRange === "past 1 month") return now - postedDate <= dayInMs * 30;
          return true;
        });

        return (
          matchesRemote &&
          matchesJobType &&
          matchesApplyDate &&
          matchesSearchTerm &&
          matchesLocation
        );
      });

      setFilteredCards(filtered); // Update the filtered cards
    };
  }, [cards, searchTerm, locationTerm]);

  useEffect(() => {
    handleSearchAndFilter();
  }, [handleSearchAndFilter, searchTerm, locationTerm]);

  return (
    <>
      <div className="opportunity_container">
        <div className="opp_search col-md-8 offset-md-2">
          <input
            type="text"
            placeholder="Job Title, Keyword"
            onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on change
          />
          <input
            type="text"
            placeholder="Enter Location"
            onChange={(e) => setLocationTerm(e.target.value)} // Update locationTerm on change
          />
          <button onClick={() => handleSearchAndFilter()}>Find Job Now</button> {/* Search on button click */}
        </div>
      </div>

      <div className="flex flex-wrap md:flex-nowrap pr-4">
        {/* Filter component */}
        <div className='md:w-1/4'>
          <JobFilter onFilterChange={handleSearchAndFilter} />
        </div>
        <div className="md:w-3/4 w-full">
          <AllOpportunities jobs={filteredCards} /> {/* Displaying filtered jobs */}
        </div>
      </div>
    </>
  );
}

export default React.memo(Opportunity);

