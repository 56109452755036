import React, { useEffect } from "react";
import '../CSS/NotFound.css';  // Add your custom CSS for styling
import notFoundImage from "../assets/404-NotFound.gif"
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className="bg-[#EBFF73] h-[100%] w-full flex items-center justify-center overflow-hidden">
      <div className="flex items-center justify-center flex-col">
        <img src={notFoundImage} alt="404 error" className="lg:w-[480px] md:w-[360px] w-[260px] mb-4" />
        <button className="home-btn" onClick={() => navigate("/")}>
          GO HOME
        </button>
      </div>
    </div>
  );
};

export default NotFound;
