import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import { MdPushPin, MdWork } from "react-icons/md";
import { format } from "date-fns";
import { toast } from 'react-hot-toast';
const API_PORT = process.env.REACT_APP_API_PORT;

const OpportunityView = () => {
  const { id } = useParams();
  const { userID, Email, First_name, Last_name, resume_file, User_Type } = useSelector((state) => ({
    ...state.profile.user,
  }));


  const [postData, setPostData] = useState(null);
  const [userImage, setUserImage] = useState({ coverImage: null, profilePic: null });
  const [author, setAuthor] = useState(false);
  const [isApplied, setIsApplied] = useState(false);

  function formatDate(date) {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return format(new Date(date), 'dd-MM-yyyy');
  }

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await axios.get(`${API_PORT}/get-post`, { params: { id } });
        if (response.status === 200) {
          setPostData(response.data.job);
        } else {
          console.error("Failed to fetch post data", response);
        }
      } catch (error) {
        console.error("Error fetching post data", error);
      }
    };

    fetchPostData();
  }, [id]);

  useEffect(() => {
    const checkIfApplied = async () => {
      const userData = {
        userEmail: Email,
        jobId: id
      }
      try {
        const { data } = await axios.get(`${API_PORT}/is-already-applied`, {
          params: { userData },
        });
        if (data.success) {
          setIsApplied(true);
        } else {
          setIsApplied(false);
        }

      } catch (error) {
        toast.error(error.response?.data?.message || "Something went wrong");
      }
    };

    if (Email && id) {
      checkIfApplied();
    }
  }, [Email, id, isApplied])

  useEffect(() => {
    if (!postData?.user_email) {
      return;
    }
    if (Email === postData.user_email) {
      setAuthor(true)
    }
    else {
      setAuthor("")
    };

    const fetchUserImage = async () => {
      try {
        const response = await axios.get(`${API_PORT}/fetch_user_img`, {
          params: { email: postData.user_email },
          withCredentials: true,
        });

        if (response.data.success) {
          setUserImage({
            coverImage: response.data.userimg,
            profilePic: response.data.profilePicture,
          });
        } else {
          console.error('Error fetching user image:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching user image:', error);
      }
    };
    fetchUserImage();
  }, [postData, Email]);

  if (!postData) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  const {
    company_name,
    profile,
    description,
    requirements,
    opportunity_type,
    responsibility,
    job_type,
    salary_min,
    salary_max,
    start_date,
    duration,
    stipend,
    stipend_max,
    skills_required,
    city,
    number_of_openings,
    address,
    deadline_apply,
  } = postData;

  const applyHandler = async (e) => {
    e.preventDefault();
    if (!Email) {
      return toast.error("Please Login to Apply");
    }

    try {
      const fullName = `${First_name} ${Last_name}`;
      const resumeLink = resume_file || "";
      const profileUrl = `${window.location.origin}/profile/student/${userID}`;
      if (!resumeLink) {
        return toast.error("Please upload your resume in profile");
      }
      // Store user data for the next API call
      const userData = {
        profileUrl: profileUrl,
        userEmail: Email,
        userName: fullName,
        resumeLink: resumeLink,
        jobId: id,
      };

      // console.log("User Data is: ", userData);

      // Apply for job using the collected user data
      const applyResponse = await axios.post(`${API_PORT}/apply-for-job`, userData);

      if (applyResponse.data.success) {
        toast.success("You have successfully applied for the job");
        setIsApplied(true);
        // console.log("Is applied status:", isApplied);
      } else {
        toast.error(applyResponse.data.message);
      }

    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-12 p-6 bg-white rounded-lg shadow-lg">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-semibold mb-4 text-orange-600">
          {opportunity_type === "job" ? "Job Opportunity" : "Internship Opportunity"}
        </h1>
        {author &&
          <Link to={`/edit-post/${id}`}>
            <button className="edit-button"> {/*RectDash CSS */}
              Edit
            </button>
          </Link>
        }
      </div>
      <div className="space-y-6">
        <div className="flex items-center">
          <img
            src={userImage.profilePic || userImage.coverImage}
            alt="Profile"
            className="w-[7rem] h-[7rem] rounded-full mr-4 object-cover" />
          <div className="ml-5">
            <h3 className="text-xl font-semibold">{company_name}</h3>
            <div className="text-gray-600 flex items-center gap-1 mb-0">
              <span><MdWork /></span>
              <span>{profile}</span>
            </div>
            <div className="text-gray-600 flex items-center gap-1">
              <span><MdPushPin /></span>
              <span>{job_type}</span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-medium text-gray-700">Job Description:</h3>
          <p className="text-gray-600">{description}</p>
        </div>

        <div>
          <h3 className="text-xl font-medium text-gray-700">Requirements:</h3>
          <p className="text-gray-600">{requirements}</p>
        </div>

        <div>
          <h3 className="text-xl font-medium text-gray-700">
            {opportunity_type === "job" ? "Job Responsibility" : "Intern's Responsibility"}:
          </h3>
          <p className="text-gray-600">{responsibility}</p>
        </div>

        {opportunity_type === "job" ? (
          <div>
            <div className="pb-4">
              <h3 className="text-xl font-medium text-gray-700 inline mr-1">Address:</h3>
              <p className="text-gray-600 inline text-lg ">{address}</p>
            </div>
            <div>
              <h3 className="text-xl font-medium text-gray-700 inline mr-1"> Salary :</h3>
              <p className="text-gray-600 inline text-lg">{(salary_min && salary_max) ? `₹ ${parseInt(salary_min)} - ${parseInt(salary_max)}` : "Not specified"}</p>
            </div>
          </div>
        ) : (
          <>
            <div>
              <h3 className="text-xl font-medium text-gray-700 inline mr-1">Internship Start Date:</h3>
              <p className="text-gray-600 inline text-lg"> {formatDate(start_date)}</p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-700 inline mr-1">Internship Duration:</h3>
              <p className="text-gray-600 inline text-lg">{duration ? `${duration} months` : "Not specified"}</p>
            </div>
            <div>
              <h3 className="text-xl font-medium text-gray-700 inline mr-1"> Salary :</h3>
              <p className="text-gray-600 inline text-lg">{(stipend && stipend_max) ? `₹ ${parseInt(stipend)} - ${parseInt(stipend_max)}` : "Not specified"}</p>
            </div>
          </>
        )}

        <div >
          <h3 className="text-xl font-medium text-gray-700">Skills Required:</h3>
          {skills_required && skills_required.split(",").map((skill) => (
            <span key={skill.trim()} className="bg-gray-200 px-3 py-2 rounded-full text-sm mr-2  hover:border-2 hover:border-orange-400">
              {skill.replace(/[[\]"]/g, '').trim()}
            </span>
          ))}
        </div>

        <div>
          <h3 className="text-xl font-medium text-gray-700 inline mr-1">City:</h3>
          <p className="text-gray-600 inline text-lg">{city}</p>
        </div>

        <div>
          <h3 className="text-xl font-medium text-gray-700 inline mr-1">Number of Openings:</h3>
          <p className="text-gray-600 inline text-lg">{number_of_openings}</p>
        </div>

        <div>
          <h3 className="text-xl font-medium text-gray-700">Deadline to Apply:</h3>
          <p className="text-gray-600 inline text-lg">{formatDate(deadline_apply)}</p>
        </div>
        {User_Type !== 2 && <div className="flex items-center justify-end m-4">
          <button
            className={`py-[20px] px-[40px] text-[#fff] text-[1.2rem] 
              ${isApplied ? "bg-green-600" : "bg-[#e95014]"}
              border-none rounded-md cursor-pointer`}
            onClick={applyHandler}
            disabled={isApplied}
          >
            {isApplied ? "Already Applied" : "Apply"}
          </button>
        </div>}
      </div>
    </div>
  );
};

export default OpportunityView;
