import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import '../../../CSS/PostedJobCard.css';
import { Link } from "react-router-dom"
import ApplicantModal from './ApplicantModal';
import { Spinner } from "../../index"

const API_PORT = process.env.REACT_APP_API_PORT;
const PostedJobCard = ({ job }) => {

  function formatDate(date) {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return format(new Date(date), 'yyyy-MM-dd');
  }

  const { id, opportunity_type, company_name, profile, salary_min, salary_max, stipend_max, stipend, deadline_apply, description, responsibility } = job;

  const UserEmail = localStorage.getItem('UserEmail')?.replace(/"/g, '') || '';
  const [coverImage, setCoverImage] = useState(null);
  const [ProfilePic, setProfilePic] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [showApplicants, setShowApplicants] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUserImage = useCallback(async () => {
    try {
      const response = await axios.get(`${API_PORT}/fetch_user_img`, {
        params: { email: UserEmail },
        withCredentials: true
      });

      if (response.data.success) {
        setCoverImage(response.data.userimg);
        setProfilePic(response.data.profilePicture);
      } else {
        console.error('Error fetching user image:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching user image:', error);
    }
  }, [UserEmail]);

  const fetchApplicants = useCallback(async () => {
    try {
      const response = await axios.get(`${API_PORT}/get-all-applied-jobs`, {
        params: { id },
      });

      if (response.data.success) {
        setApplicants(response.data.jobs);
      } else {
        console.error('Error fetching applicants:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  }, [id]);

  const handleOpenApplicantsModal = async () => {
    setLoading(true);
    await fetchApplicants();
    setShowApplicants(true);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchApplicants();
    }
  }, [id, fetchApplicants]);


  useEffect(() => {
    fetchUserImage();
  }, [fetchUserImage]);

  const renderSalaryOrStipend = () => {
    if (opportunity_type === 'job') {
      if (salary_min && salary_max) {
        return `${parseInt(salary_min, 10).toLocaleString()} - ${parseInt(salary_max, 10).toLocaleString()} / Month`;
      } else {
        return 'Not Disclosed';
      }
    } else if (opportunity_type === 'internship') {
      if (stipend && stipend_max) {
        return `${parseInt(stipend, 10).toLocaleString()} - ${parseInt(stipend_max, 10).toLocaleString()} / Month`;
      } else {
        return 'Not Disclosed';
      }
    }
  };

  const renderShortDescription = (description) => {
    const wordLimit = 10; // Set the word limit for truncation

    // Handle cases where description might be null or undefined
    if (!description) {
      return 'Description not available'; // or an empty string or any other fallback
    }

    const words = description.split(' ');

    // If the description length is less than or equal to the word limit, return the full description
    if (words.length <= wordLimit) {
      return description;
    }

    // If expanded, show the full description with "Show less" button
    if (isExpanded) {
      return (
        <>
          {description}
          <button
            onClick={(e) => { e.preventDefault(); setIsExpanded(false); }}
            className="text-blue-500 underline ml-1"
          >
            Show less
          </button>
        </>
      );
    }

    // If not expanded, show truncated description with "Read more" button
    return (
      <>
        {words.slice(0, wordLimit).join(' ')}...
        <button
          onClick={(e) => { e.preventDefault(); setIsExpanded(true); }}
          className="text-blue-500 underline ml-1"
        >
          Read more
        </button>
      </>
    );
  };

  return (
    <div className="p-4 border rounded-lg shadow-md mb-4">
      {loading && <Spinner />}
      <Link to={`/job-listing/${id}`} className='no-underline text-black'>
        <div className="flex items-center mb-2">
          <img
            src={ProfilePic ? ProfilePic : coverImage}
            alt="Profile"
            className="w-20 h-20 rounded-full mr-4"
          />
          <div>
            <h3 className="text-lg font-semibold">{company_name}</h3>
            <p className="text-sm text-gray-600">{profile}</p>
          </div>
        </div>
        <div className="mb-2">
          <p><strong>Opportunity Type:</strong> <span className='capitalize'>{opportunity_type}</span></p>
          <p><strong>Compensation:</strong> {renderSalaryOrStipend()}</p>
          <p><strong>Deadline:</strong> {formatDate(deadline_apply)}</p>
        </div>
        <p className="descriptionCard text-gray-700 mb-4">
          {description ? renderShortDescription(description) : renderShortDescription(responsibility)}
        </p>
        <div>
          <p><strong>Total Applicants:</strong> <span>{applicants.length}</span></p>
        </div>
      </Link>
      <div className='flex items-center justify-center'>
        <button
          onClick={handleOpenApplicantsModal}
          className="bg-blue-500 hover:bg-slate-50 text-slate-50 hover:text-blue-500 py-2 px-4 rounded mt-4 border-[2px] border-blue-500 transition-all duration-300 font-semibold">
          View Applicants
        </button>
      </div>
      {showApplicants && <ApplicantModal onClose={() => setShowApplicants(false)} applicants={applicants} />}
    </div>
  );
};

export default PostedJobCard;
