import React from 'react';
import { FaUser, FaListAlt, FaBriefcase } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHeadset, faUserTie, faTasks, faChartLine, faComments, faBookOpen, faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import HeadImg from '../../../assets/headimg.png'; // Adjust the path as necessary
import '../../../CSS/StudentDashboard.css';

const StudentDashboard = () => {
  const categories = [
    { icon: faEnvelope, name: 'Marketing' },
    { icon: faHeadset, name: 'Customer Service' },
    { icon: faUserTie, name: 'Human Resource' },
    { icon: faTasks, name: 'Project Management' },
    { icon: faChartLine, name: 'Business Development' },
    { icon: faComments, name: 'Sales & Communication' },
    { icon: faBookOpen, name: 'Teaching & Education' },
    { icon: faPencilRuler, name: 'Design & Creative' },
  ];

  return (
    <>
      <div className="app">
        <header className="header">
          <div className="header-content">
            <div className="text">
              <h1>Discover Your Perfect Job: Matching Your Interests and Skills</h1>
              <p>Unlock Your Potential, Embrace Your Future.</p>

              <div className="search-bar">
                <input type="text" placeholder="Job Title, Keyword" />
                <input type="text" placeholder="Enter Location" />
                <button>Find Job Now</button>
              </div>

              <div className="suggestions">
                <span>Suggestion:</span>
                <span>Garments/Textile</span>,
                <span>Medical/Pharma</span>,
                <span>IT & Telecommunication</span>,
                <span>Others</span>
              </div>
            </div>
            <div className="image">
              <img src={HeadImg} alt="Job Search Illustration" />
            </div>
          </div>
        </header>
        <div className="categories-container">
          <h1>Explore By Category</h1>
          <div className="categories-grid">
            {categories.map((category, index) => (
              <div key={index} className="category-card">
                <FontAwesomeIcon icon={category.icon} size="2x" />
                <h2>{category.name}</h2>
              </div>
            ))}
          </div>
        </div>
        <div className="steps-container">
          <div className="step-card">
            <FaUser className="step-icon" />
            <h3>Register your account</h3>
            <p>By registering an account, you can use our basic features to find hundreds of exciting jobs.</p>
          </div>
          <div className="step-card">
            <FaListAlt className="step-icon" />
            <h3>Select your preference</h3>
            <p>Discover jobs most relevant to you by experience level, salary, location, job type, etc.</p>
          </div>
          <div className="step-card">
            <FaBriefcase className="step-icon" />
            <h3>Wait for recruiters action</h3>
            <p>Your next step: waiting for recruiters to review your application and reach out.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentDashboard;
