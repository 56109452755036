import React, { useRef, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Spinner, Footer } from '../../index'
import "../../../CSS/Profile.css";
import { useDispatch, useSelector } from 'react-redux';
import { updateProfilePicture } from '../../../store/slices/studentSlices/basicInformation/profileSlice';

const API_PORT = process.env.REACT_APP_API_PORT;
const Empprofile = () => {
  const dispatch = useDispatch()

  const {
    First_name, Last_name, Company_name, Email, Image,
    profile_picture, Bio,
  } = useSelector((state) => ({
    ...state.profile.user,
  }));

  const [loading, setLoading] = useState(false);
  const input_img = useRef(null);

  const openChooseFile = () => {
    if (input_img.current) {
      input_img.current.click();
    } else {
      console.error('input_img is not attached to any DOM element');
    }
  };

  const handleChangeCover = async (event) => {
    const selectedFile = event.target.files[0];
    const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      // Frontend file type validation
      if (!ALLOWED_TYPES.includes(selectedFile.type)) {
        toast.error('Invalid file type. Only PNG, JPEG, and JPG files are allowed.');
        return;
      }
      if (selectedFile.size > maxSize) {
        toast.error(`File size exceeds the ${maxSize / (1024 * 1024)}MB limit. Please upload a smaller file.`);
        return false;
      }

      // Prepare form data for file upload
      const formData = new FormData();
      formData.append('profile_picture', selectedFile);
      formData.append('email', Email);

      // Upload file to the server
      try {
        setLoading(true);
        const response = await axios.post(`${API_PORT}/upload-profile-picture`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.success) {
          toast.success(response.data.message);
          dispatch(updateProfilePicture(URL.createObjectURL(selectedFile)))
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Error uploading profile picture';
        toast.error(errorMessage);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className='profileDashboard'>
        <h1 className='profiletitle'>Profile</h1>
        <div className='box user-container mt-0'>
          <div className='user-avatar'>
            <span className="userProfileImage">
              <img src={profile_picture ? profile_picture : Image} alt="User Profile" className='profileImage' />
              <button onClick={openChooseFile} className='profile-change-button'>
                <svg width="1.2em" fill="currentColor" viewBox="0 0 20 20" className='profile-change-icon'>
                  <path fillRule="evenodd" clipRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" />
                </svg>
                <input ref={input_img} type="file" onChange={handleChangeCover} hidden />
              </button>
            </span>
          </div>
          <div className='profile-details'>
            <span className='user-name'>{First_name} {Last_name}</span>
            <span className='text-[18px] capitalize text-black mt-4'>{Company_name}</span>
            <span className='user-email'>{Email}</span>
          </div>
          <div className='profile-info'>
            <div className='buttons'>
              <NavLink to="/edit-emp-profile" className='profile-button text-center no-underline'>Edit Profile</NavLink>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          {Bio && (
            <section className="box">
              <p className="heading">ABOUT COMPANY</p>
              <p className="about-desc">{Bio}</p>
            </section>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Empprofile;
