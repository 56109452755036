import React from 'react';
import { MdDeleteForever } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { deleteEducation } from "../../../store/index"


const EducationCard = (education) => {
  const dispatch = useDispatch();

  const handleDelete = async (srno) => {
    dispatch(deleteEducation({ srno }));
  };
  if (education.length === 0) {
    return <p>No achievements found.</p>;
  }

  return (
    <div className='education-list'>
      {education.map((edu, index) => (
        <div key={index} className='education-item'>
          <div className='delete-icon' onClick={() => handleDelete(edu.srno)}>
            <MdDeleteForever size={20} />
          </div>
          <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Institute: {edu.Institute}</p>
          <p className='text-[1rem] leading-8 text-[#002b36]'>Degree: {edu.Degree}</p>
          <p className='text-[1rem] leading-8 text-[#002b36]'>Start Year: {edu.Start_year}</p>
          <p className='text-[1rem] leading-8 text-[#002b36]'>End Year: {edu.End_year}</p>
          <p className='text-[1rem] leading-8 text-[#002b36]'>CGPA: {edu.CGPA}</p>
          <p className='text-[1rem] leading-8 text-[#002b36]'>Specialization: {edu.Specialization}</p>
        </div>
      ))}
    </div>
  );
};

export default EducationCard;