import React from 'react';

const Card = ({ title, content, icon, tit, cont }) => {
  return (
    <div className="card ">
      <div className="card-title">{title}</div>
      <div className="card-content">{content}</div>
      <div className="val">
        <div className="val-icon">{icon}</div>
        <div className="val-title">{tit}</div>
        <div className="val-content">{cont}</div>
      </div>
    </div>
  );
};

export default Card;