import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { JobPostForm } from '../../index';

const API_PORT = process.env.REACT_APP_API_PORT;
const EditPost = () => {
  const { id } = useParams();
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await axios.get(`${API_PORT}/get-post`, { params: { id } });
        if (response.status === 200) {
          setPostData(response.data.job);
        } else {
          console.error("Failed to fetch post data", response);
        }
      } catch (error) {
        console.error("Error fetching post data", error);
      }
    };
    fetchPostData();
  }, [id]);

  return postData ? (
    <div className='py-8'>
      <JobPostForm postData={postData} />
    </div>
  ) : null
};

export default EditPost;