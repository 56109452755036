import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_PORT = process.env.REACT_APP_API_PORT;

export const fetchProjectData = createAsyncThunk(
  'Project',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_PORT}/get-projects?email=${email}`);
      if (!response.data.success) {
        return rejectWithValue(response.data.message || 'Failed to fetch Project');
      }
      return response.data.projects;
    } catch (error) {
      return rejectWithValue('Failed to fetch Project');
    }
  }
);

export const deleteProject = createAsyncThunk(
  'deleteProject',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${API_PORT}/delete-project?id=${projectId}`);
      if (!response.data.success) {
        return rejectWithValue(response.data.message || 'Failed to delete Project');
      }
      return projectId;
    } catch (error) {
      return rejectWithValue(`Error deleting Project`);
    }
  }
);