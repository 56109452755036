import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../index'
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../store';
const API_PORT = process.env.REACT_APP_API_PORT;

const EmpProfileData = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.profile);

  const [formData, setFormData] = useState({
    firstName: user.First_name,
    lastName: user.Last_name || '',
    email: user.Email,
    CompanyName: user.Company_name || '',
    mobile: user.Mobile || '',
    bio: user.Bio || '',
    link1: user.Link1 || '',
    link2: user.Link2 || '',
    link3: user.Link3 || '',
    link4: user.Link4 || ''
  });

  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const resp = await axios.put(`${API_PORT}/update-employer-data`, formData);
      if (resp.data.success) {
        const userDetails = {
          id: user.userID,
          type: JSON.stringify(user.User_Type),
        };
        dispatch(getUserDetails(userDetails))
        // console.log('User data updated successfully');
        toast.success(resp.data.message);
        Navigate(`/profile/recruiter/${user.userID}`)
      }
    } catch (err) {
      console.error('Error updating user data:', err);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <section className='box'>
      {loading && <Spinner />}
      <p className="heading">Basic Information</p>
      <form onSubmit={handleSubmit}>
        <div className='row-name'>
          <div className='col-name'>
            <label>First Name*</label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required className='input-fields' placeholder='Enter your First Name' />
          </div>
          <div className='col-name mb-3'>
            <label>Last Name*</label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required className='input-fields' placeholder='Enter your Last Name' />
          </div>
        </div>
        <div className='col-name'>
          <label>Company Name*</label>
          <input type="text" name="CompanyName" value={formData.CompanyName} onChange={handleChange} required className='input-fields-about' placeholder='Enter your Company Name' />
        </div>

        <div className='row-name'>
          <div className='col-name'>
            <label>Email Id*</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} className='input-fields' disabled />
          </div>
          <div className='col-name'>
            <label>Mobile No.*</label>
            <input type="Number" name="mobile" value={formData.mobile} onChange={handleChange} required className='input-fields' placeholder='Enter your Contact Number' min={0} />
          </div>
        </div>
        <div className='mt-4'>
          <div className='row-name'>
            <div className='col-name'>
              <label>Social Links</label>
              <input type="text" name="link1" value={formData.link1} onChange={handleChange} className='input-fields' placeholder='company url' required />
            </div >
            <div className='col-name mt-6'>
              <input type="text" name="link2" value={formData.link2} onChange={handleChange} className='input-fields' placeholder='Enter your LinkedIn Url' required />
            </div >
          </div >

          <div className='row-name'>
            <div className='col-name'>
              <input type="text" name="link3" value={formData.link3} onChange={handleChange} className='input-fields' placeholder='Social Link 3' />
            </div >
            <div className='col-name'>
              <input type="text" name="link4" value={formData.link4} onChange={handleChange} className='input-fields' placeholder='Social Link 4' />
            </div >
          </div >

          <label className='mt-4'>Company Details</label>
          <textarea name="bio" value={formData.bio} onChange={handleChange} rows='5' spellCheck='false' required className='input-fields-about' placeholder='Describe about yourself'></textarea>

          <button type="submit" className='update-details-btn'>Update Details</button>
        </div >
      </form>
    </section>
  );
};

export default EmpProfileData;
