import { createSlice } from "@reduxjs/toolkit";
import { fetchEducationData, deleteEducation } from "./EducationActions";

const initialState = {
  education: [],
  error: null,
}

const educationSlice = createSlice({
  name: "education",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchEducationData.fulfilled, (state, action) => {
        state.education = action.payload;
        state.error = null;
      })
      .addCase(fetchEducationData.rejected, (state, action) => {
        state.error = action.payload;
      })

      // delete Education
      .addCase(deleteEducation.fulfilled, (state, action) => {
        state.education = state.education.filter(item => item.srno !== action.payload);
        state.error = null;
      })
      .addCase(deleteEducation.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
})

export default educationSlice.reducer;
