import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkExpData } from '../../../store';
import toast from 'react-hot-toast';
import { ExperienceForm } from './ExperienceForm';
import ExperienceCard from './ExperienceCard';

const WorkExperience = () => {
  const API_PORT = process.env.REACT_APP_API_PORT;
  const dispatch = useDispatch()

  const { Email } = useSelector((state) => state.profile.user);
  const { WorkExperience } = useSelector((state) => state.experiences)

  const [WorkExperienceData, setWorkExperienceData] = useState({
    designation: '',
    startYear: '',
    endYear: '',
    organizationName: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkExperienceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (WorkExperienceData.startYear > WorkExperienceData.endYear) {
      toast.error('Start year cannot be greater than end year');
      return;
    }
    try {
      const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');
      console.log("heyyyy", UserEmail);
      const response = await axios.post(`${API_PORT}/add-workexp`, WorkExperienceData, {
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': UserEmail,
        },
      });

      if (response.data.success) {
        dispatch(fetchWorkExpData(Email))
        setWorkExperienceData({
          designation: '',
          startYear: '',
          endYear: '',
          organizationName: '',
        });

        // Hide the form after submission
        setFormVisible(false);

      } else {
        console.error('Failed to add work experience:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding work experience:', error);
    }
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };

  return (
    <section className='box'>
      <p className="heading">Work Experience</p>
      {ExperienceCard(WorkExperience)}
      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New
        </div>
      )}
      {isFormVisible && (
        <ExperienceForm
          formData={WorkExperienceData}
          handleChange={(e) => handleChange(e)}
          handleSubmit={(e) => handleSubmit(e)}
        />
      )}
    </section>
  );
};

export default WorkExperience;
