// src/store/loginSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import { loginUser, loginWithGoogle } from './loginActions';

// Initial state
const initialState = {
  isLoggedIn: false,
  loading: false,
  error: null,
  userEmail: null,
  userId: null,
  userType: null,
  token: null,
};

// Create the login slice
const loginSlice = createSlice({
  name: 'authSlice/login',
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    reload(state) {
      state.loading = false;
      state.isLoggedIn = true;
      state.error = null;
      state.userEmail = localStorage.getItem("UserEmail") ?
        JSON.parse(localStorage.getItem("UserEmail")) : null;
      state.userId = localStorage.getItem("id") ?
        JSON.parse(localStorage.getItem("id")) : null;
      state.userType = localStorage.getItem("UserType") ?
        JSON.parse(localStorage.getItem("UserType")) : null;
      state.token = localStorage.getItem("token") ?
        JSON.parse(localStorage.getItem("token")) : null;
    },
    logout: (state) => initialState,
  },
  extraReducers: (builder) => {
    // Handle email/password login
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        // console.log(action.payload)

        state.token = action.payload.token;
        state.userEmail = action.payload.email;
        state.userId = action.payload.userID;
        state.userType = action.payload.userType;
        toast.success(action.payload.message || 'Logged in successfully');
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload || 'Login failed');
      });

    // Handle Google login
    builder
      .addCase(loginWithGoogle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;

        state.token = action.payload.token;
        state.userId = action.payload.userID;
        state.userEmail = action.payload.email;
        state.userType = action.payload.userType;
        toast.success(action.payload.message || 'Logged in with Google successfully');
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload || 'Google login failed');
      });
  },
});

// Export actions
export const { reload, setLoggedIn, logout } = loginSlice.actions;

// Export reducer
export default loginSlice.reducer;
