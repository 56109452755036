import { createAsyncThunk } from "@reduxjs/toolkit";

const API_PORT = process.env.REACT_APP_API_PORT;

export const fetchEducationData = createAsyncThunk(
  'Education',
  async (email, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PORT}/get-education`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': email
        }
      });
      const data = await response.json();
      if (!data.success) {
        return rejectWithValue(data.message || 'Failed to fetch education');
      }
      return data.education;
    } catch (error) {
      return rejectWithValue('Failed to fetch education');
    }
  }
);

export const deleteEducation = createAsyncThunk(
  'deleteEducation',
  async ({ srno }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PORT}/delete-education?srno=${srno}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();

      if (!data.success) {
        return rejectWithValue(data.message || `Failed to delete Education`);
      }
      return srno;
    } catch (error) {
      return rejectWithValue(`Error deleting Education`);
    }
  }
);