import { createAsyncThunk } from "@reduxjs/toolkit";

export const getUserDetails = createAsyncThunk(
  '/profileSlice',
  async (userCredentials, { rejectWithValue }) => {
    const API_PORT = process.env.REACT_APP_API_PORT;
    let url;
    if (userCredentials.type === "1") {
      url = `${API_PORT}/fetch-user-data?id=${userCredentials.id}`;
    } else if (userCredentials.type === "2") {
      url = `${API_PORT}/fetch-emp-data?id=${userCredentials.id}`;
    } else {
      return rejectWithValue('Invalid user type');
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (!data.success) {
        return rejectWithValue(data.message || 'Failed to fetch user data');
      }

      return data;
    } catch (error) {
      return rejectWithValue('Network error: Failed to fetch user data');
    }
  }
);