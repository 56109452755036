/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import JobCard from './PostedJobCard';
import axios from 'axios';
import { format } from 'date-fns';

const PostedJob = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatDate = (isoDate) => {
    if (!isoDate) return ''; // Ensure date is not undefined or null
    return format(new Date(isoDate), 'yyyy-MM-dd'); // or return moment(isoDate).format('MMMM D, YYYY');
  };
  const API_PORT = process.env.REACT_APP_API_PORT;
  const fetchJobs = async () => {
    try {
      const email = localStorage.getItem('UserEmail').replace(/"/g, '');
      const response = await axios.get(`${API_PORT}/posted-jobs`, { params: { email } });
      setJobs(response.data.jobs);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobs();
    formatDate();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        {jobs.length > 0 ?
          "Posted Jobs"
          : "No jobs Posted"
        }
      </h2>

      <div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          {jobs.map((job, index) => (
            <JobCard key={index} job={job} />
          ))}
        </div>

      </div>
    </div>
  );
};

export default PostedJob;
