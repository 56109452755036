import React, { useState } from "react";
import Select from 'react-select';
import skillsList from '../../data/SkillList'
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useSelector } from "react-redux";
const API_PORT = process.env.REACT_APP_API_PORT;

const FormField = ({ label, type, name, value, onChange, placeholder, options, min, readOnly }) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    {type === "textarea" ? (
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        rows="4"
        className="mt-1 block w-full p-2 border-2 rounded-md border-gray-300 focus:border-orange-500"
      ></textarea>
    ) : type === "radio" ? (
      <div className="flex space-x-4 mt-2">
        {options.map((option) => (
          <label key={option.value} className="flex items-center">
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
              className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
            />
            <span className="ml-2 text-sm">{option.label}</span>
          </label>
        ))}
      </div>
    ) : (
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="mt-1 block w-full p-2 border-2 rounded-md border-gray-300 focus:border-orange-500"
        min={min}
        readOnly={readOnly}

      />
    )}
  </div>
);

const JobPostForm = ({ postData }) => {
  const {
    Company_name, Email
  } = useSelector((state) => ({
    ...state.profile.user,
  }));
  const [opportunityType, setOpportunityType] = useState(postData?.opportunity_type ?? "job");
  const id = postData?.id;

  function formatDate(date) {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return format(new Date(date), 'yyyy-MM-dd');
  }

  const [formData, setFormData] = useState({
    companyName: Company_name,
    jobProfile: postData?.profile || "",
    jobDescription: postData?.description || "",
    jobResponsibility: postData?.responsibility || "",
    jobAddress: postData?.address || "",
    jobType: postData?.job_type || "",
    salaryMin: postData?.salary_min || "",
    salaryMax: postData?.salary_max || "",
    internshipProfile: postData?.profile || "",
    internsResponsibility: postData?.responsibility || "",
    internshipStartDate: formatDate(postData?.start_date) || "",
    internshipDuration: postData?.duration || "",
    internshipType: postData?.job_type || "",
    stipend: postData?.stipend || "",
    stipendMax: postData?.stipend_max || "",
    requirements: postData?.requirements || "",
    city: postData?.city || "",
    skillsRequired: postData?.skills_required || [],
    numberOfOpenings: postData?.number_of_openings || "",
    deadlineApply: formatDate(postData?.deadline_apply) || "",
  });
  const Navigate = useNavigate('');


  const handleOpportunityTypeChange = (e) => {
    setOpportunityType(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSkillsChange = (selectedOptions) => {
    const selectedSkills = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData((prevFormData) => ({
      ...prevFormData,
      skillsRequired: selectedSkills
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Determine if it's a job or internship
    const isSkillsSelected = formData.skillsRequired.length > 0;
    if (!isSkillsSelected) {
      toast.error("Skills are required");
      return;
    }

    if ((formData.salaryMin && !formData.salaryMax) || (!formData.salaryMin && formData.salaryMax)) {
      toast.error("Salary Both field is required");
      return;
    }
    if ((formData.stipend && !formData.stipendMax) || (!formData.stipend && formData.stipendMax)) {
      toast.error("Stipend Both field is required");
      return;
    }

    const postData = {
      opportunity_type: opportunityType,
      company_name: Company_name,
      profile: opportunityType === "job" ? formData.jobProfile : formData.internshipProfile,
      skills_required: JSON.stringify(formData.skillsRequired),
      job_type: opportunityType === "job" ? formData.jobType : formData.internshipType,
      city: formData.city,
      number_of_openings: formData.numberOfOpenings,
      start_date: opportunityType === "internship" ? formData.internshipStartDate : null,
      duration: opportunityType === "internship" ? formData.internshipDuration : null,
      responsibility: opportunityType === "job" ? formData.jobResponsibility : formData.internsResponsibility,
      requirements: formData.requirements,
      salary_min: opportunityType === "job" ? formData.salaryMin : null,
      salary_max: opportunityType === "job" ? formData.salaryMax : null,
      stipend: opportunityType === "internship" ? formData.stipend : null,
      stipend_max: opportunityType === "internship" ? formData.stipendMax : null,
      description: formData.jobDescription,
      address: formData.jobAddress,
      deadline_apply: formData.deadlineApply,
      user_email: Email,
    };
    if (id) {
      postData.id = id;
    }
    try {
      const url = id ? `${API_PORT}/update-post/${id}` : `${API_PORT}/post-job/internship`;
      const method = id ? "PUT" : "POST";
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      const data = await response.json();

      if (!response.ok) {
        console.error(`Failed to submit form data. Status: ${response.status} - ${response.statusText}`);
        console.error('Error details:', data);
        toast.error(data.message || 'Failed to submit form data.');
      } else {
        toast.success('Submission complete. We appreciate your contribution!');
        console.log("Form submitted successfully");
        Navigate('/')
      }
    } catch (error) {
      console.error("Failed to submit form data", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const jobFields = [
    { label: "Company Name *", type: "text", name: "companyName", placeholder: "" },
    { label: "Job Profile *", type: "text", name: "jobProfile", placeholder: "e.g. Web Developer" },
    { label: "Skills Required *", type: "select", name: "skillsRequired", placeholder: "", isSkills: true },
    {
      label: "Job Type *",
      type: "radio",
      name: "jobType",
      options: [
        { label: "In Office", value: "in-office" },
        { label: "Hybrid", value: "hybrid" },
        { label: "Remote", value: "remote" },
      ],
    },
    { label: "City *", type: "text", name: "city", placeholder: "" },
    { label: "Number of Openings *", type: "number", name: "numberOfOpenings", placeholder: "", min: 0 },
    { label: "Minimum Salary", type: "number", name: "salaryMin", placeholder: "", min: 0 },
    { label: "Maximum Salary", type: "number", name: "salaryMax", placeholder: "", min: 0 },
    { label: "Job Description *", type: "textarea", name: "jobDescription", placeholder: "" },
    { label: "Job Address *", type: "textarea", name: "jobAddress", placeholder: "" },
    { label: "Job Responsibility *", type: "textarea", name: "jobResponsibility", placeholder: "" },
    { label: "Requirements *", type: "textarea", name: "requirements", placeholder: "" },
    { label: "Deadline to Apply *", type: "date", name: "deadlineApply", placeholder: "" },
  ];

  const internshipFields = [
    { label: "Company Name *", type: "text", name: "companyName", placeholder: "" },
    { label: "Internship Profile *", type: "text", name: "internshipProfile", placeholder: "e.g. Web Developer" },
    { label: "Skills Required *", type: "select", name: "skillsRequired", placeholder: "", isSkills: true },
    {
      label: "Internship Type *",
      type: "radio",
      name: "internshipType",
      options: [
        { label: "In Office", value: "in-office" },
        { label: "Hybrid", value: "hybrid" },
        { label: "Remote", value: "remote" },
      ],
    },
    { label: "City *", type: "text", name: "city", placeholder: "" },
    { label: "Number of Openings *", type: "number", name: "numberOfOpenings", placeholder: "", min: 0 },
    { label: "Stipend Minimum", type: "number", name: "stipend", placeholder: "", min: 0 },
    { label: "Stipend Maximum", type: "number", name: "stipendMax", placeholder: "", min: 0 },
    { label: "Internship Start Date *", type: "date", name: "internshipStartDate", placeholder: "" },
    { label: "Internship Duration (in months) *", type: "number", name: "internshipDuration", placeholder: "", min: 0 },
    { label: "Interns Responsibility *", type: "textarea", name: "internsResponsibility", placeholder: "" },
    { label: "Requirements *", type: "textarea", name: "requirements", placeholder: "" },
    { label: "Deadline to Apply *", type: "date", name: "deadlineApply", placeholder: "" },
  ];
  const fields = opportunityType === "job" ? jobFields : internshipFields;

  return (
    <div className="max-w-3xl mx-auto mt-3 p-6 bg-white rounded-lg shadow-lg md:p-8 lg:p-10">
      <h2 className="text-2xl font-semibold mb-4 text-orange-600">
        {id ? 'Update Internship/Job' : 'Post Internship/Job'}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4 mt-8">
        <div>
          <span className="block text-sm font-medium text-gray-700">
            Opportunity Type
          </span>
          <div className="flex space-x-4 mt-2 mb-2">
            <label className="flex items-center">
              <input
                type="radio"
                value="job"
                checked={opportunityType === "job"}
                onChange={handleOpportunityTypeChange}
                className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
              />
              <span className="ml-2 text-sm">Job</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="internship"
                checked={opportunityType === "internship"}
                onChange={handleOpportunityTypeChange}
                className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
              />
              <span className="ml-2 text-sm">Internship</span>
            </label>
          </div>
        </div>


        {fields.map((field) =>
          field.name === 'companyName' ? (
            <FormField
              key={field.name}
              label={field.label}
              type={field.type}
              name={field.name}
              value={Company_name}
              onChange={handleInputChange}
              placeholder={field.placeholder}
              readOnly
            />
          ) : field.isSkills ? (
            <div key={field.name}>
              <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                {field.label}
              </label>
              <Select
                isMulti
                name={field.name}
                options={skillsList}
                className="mt-1 block w-full"
                classNamePrefix="select"
                onChange={handleSkillsChange}
                value={skillsList.filter((skill) =>
                  formData.skillsRequired.includes(skill.value)
                )}
              />
            </div>
          ) : (
            <FormField
              key={field.name}
              label={field.label}
              type={field.type}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
              placeholder={field.placeholder}
              options={field.options}
              min={field.min}
            />
          )
        )}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full py-2 px-4 bg-orange-600 text-white font-semibold rounded-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            {id ? 'Update Opportunity' : 'Post Opportunity'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobPostForm;
