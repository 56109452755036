import { createSlice } from "@reduxjs/toolkit";
import { deleteProject, fetchProjectData } from "./ProjectActions";

const initialState = {
  project: [],
  error: null,
}

const projectSlice = createSlice({
  name: "project",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectData.fulfilled, (state, action) => {
        state.project = action.payload;
        state.error = null;
      })
      .addCase(fetchProjectData.rejected, (state, action) => {
        state.error = action.payload;
      })

      // delete project
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.project = state.project.filter(item => item.ProjectID !== action.payload);
        state.error = null;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
})

export default projectSlice.reducer;