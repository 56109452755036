import { createSlice } from "@reduxjs/toolkit";
import { deleteAchievement, deleteCertificate, fetchAchievements, fetchCertifications } from "./acheivementActions";


const initialState = {
  achievements: [],
  certificates: [],
  error: null,
};

const achievementSlice = createSlice({
  name: "achievementsAndCertificates",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get achievements
      .addCase(fetchAchievements.fulfilled, (state, action) => {
        state.achievements = action.payload;
        state.error = null;
      })
      .addCase(fetchAchievements.rejected, (state, action) => {
        state.error = action.payload;
      })

      // Get certifications
      .addCase(fetchCertifications.fulfilled, (state, action) => {
        state.certificates = action.payload;
        state.error = null;
      })
      .addCase(fetchCertifications.rejected, (state, action) => {
        state.error = action.payload;
      })

      // delete achievement
      .addCase(deleteAchievement.fulfilled, (state, action) => {
        state.achievements = state.achievements.filter(item => item.ID !== action.payload);
        state.error = null;
      })
      .addCase(deleteAchievement.rejected, (state, action) => {
        state.error = action.payload;
      })

      // delete Certificate
      .addCase(deleteCertificate.fulfilled, (state, action) => {
        state.certificates = state.certificates.filter(item => item.ID !== action.payload);
        state.error = null;
      })
      .addCase(deleteCertificate.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
});

export default achievementSlice.reducer;
