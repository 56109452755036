import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_PORT = process.env.REACT_APP_API_PORT;

export const fetchAchievements = createAsyncThunk(
  'user/achievements',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_PORT}/get-achievements?email=${email}`);
      if (!response.data.success) {
        return rejectWithValue(response.data.message || 'Failed to fetch achievements');
      }
      return response.data.achievements;
    } catch (error) {
      return rejectWithValue('Failed to fetch achievements');
    }
  }
);

export const fetchCertifications = createAsyncThunk(
  'user/certificates',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_PORT}/get-certifications?email=${email}`);
      if (!response.data.success) {
        return rejectWithValue(response.data.message || 'Failed to fetch achievements');
      }
      return response.data.certifications;
    } catch (error) {
      return rejectWithValue('Failed to fetch achievements');
    }
  }
);

export const deleteAchievement = createAsyncThunk(
  'user/deleteAchievement',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${API_PORT}/delete-achievements?id=${id}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.data.success) {
        return rejectWithValue(response.data.message || `Failed to delete achievements`);
      }
      return id;
    } catch (error) {
      return rejectWithValue(`Error deleting achievements`);
    }
  }
);

export const deleteCertificate = createAsyncThunk(
  'user/deleteCertificates',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${API_PORT}/delete-certifications?id=${id}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.data.success) {
        return rejectWithValue(response.data.message || `Failed to delete certifications`);
      }
      return id;
    } catch (error) {
      return rejectWithValue(`Error deleting certifications`);
    }
  }
);