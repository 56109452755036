import React, { useState } from 'react';
import toast from 'react-hot-toast';
import EducationCard from './EducationCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEducationData } from '../../../store';
import { EducationForm } from './EducationFrom';

const API_PORT = process.env.REACT_APP_API_PORT;

const EducationDetails = () => {
  const dispatch = useDispatch()
  const { Email } = useSelector((state) => state.profile.user);
  const { education } = useSelector((state) => state.educations)

  const [educationData, setEducationData] = useState({
    degree: '',
    startYear: '',
    endYear: '',
    cgpa: '',
    specialization: '',
    institute: '',
    campus: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEducationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (educationData.startYear > educationData.endYear) {
      toast.error('Start year cannot be greater than end year');
      return;
    }

    fetch(`${API_PORT}/add-education`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-user-email': Email
      },
      body: JSON.stringify(educationData)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          toast.success(data.message);
          dispatch(fetchEducationData(Email));
          setEducationData({
            degree: '',
            startYear: '',
            endYear: '',
            cgpa: '',
            specialization: '',
            institute: '',
            campus: '',
          });


          setFormVisible(false);
        } else {
          console.error('Failed to add education details:', data.message);
          toast.error(data.message);
        }
      })
      .catch(error => {
        toast.error(error.response?.data?.message);
        console.error('Error:', error);
      });
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };


  return (
    <section className='box'>
      <p className="heading">Education Details</p>
      {EducationCard(education)}
      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New
        </div>
      )}
      {isFormVisible && (
        <EducationForm
          formData={educationData}
          handleChange={(e) => handleChange(e)}
          handleSubmit={(e) => handleSubmit(e)}
        />
      )}
    </section>
  );
};

export default EducationDetails;
