import { useState, useEffect } from "react";
import { FaFilter } from "react-icons/fa";
import '../../../CSS/jobfilter.css'; // Import the CSS file

function JobFilter({ onFilterChange }) {
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [selectedRemote, setSelectedRemote] = useState([]);
  const [selectedApplyDates, setSelectedApplyDates] = useState([]);


  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleCheckboxChange = (filterId, value) => {
    let updatedState;
    if (filterId === "remote") {
      updatedState = selectedRemote.includes(value)
        ? selectedRemote.filter(v => v !== value)
        : [...selectedRemote, value];
      setSelectedRemote(updatedState);
    } else if (filterId === "jobType") {
      updatedState = selectedJobTypes.includes(value)
        ? selectedJobTypes.filter(v => v !== value)
        : [...selectedJobTypes, value];
      setSelectedJobTypes(updatedState);
    } else if (filterId === "datePosted") {
      updatedState = selectedApplyDates.includes(value)
        ? selectedApplyDates.filter(v => v !== value)
        : [...selectedApplyDates, value];
      setSelectedApplyDates(updatedState);
    }
  };

  useEffect(() => {
    onFilterChange({
      remote: selectedRemote,
      jobType: selectedJobTypes,
      applyDate: selectedApplyDates,
    });
  }, [selectedRemote, selectedJobTypes, selectedApplyDates, onFilterChange]);

  const filters = [
    {
      id: "remote",
      name: "Remote",
      options: [
        { value: "hybrid", label: "Hybrid" },
        { value: "remote", label: "Remote" },
        { value: "in-office", label: "On-site" },
      ],
    },
    {
      id: "datePosted",
      name: "Posted",
      options: [
        { value: "past 24 hours", label: "Past 24 hours" },
        { value: "past 1 week", label: "Past 1 week" },
        { value: "past 1 month", label: "Past 1 month" },
      ],
    },
    {
      id: "jobType",
      name: "Job Type",
      options: [
        { value: "job", label: "Job" },
        { value: "internship", label: "Internship" },
      ],
    },
  ];

  return (
    <>
      <div className='filter-button-container'>
        <button onClick={toggleFilter} className='filter-button'>
          <FaFilter />
          <span>Filter</span>
        </button>
      </div>

      <div className={`filter-panel ml-5  ${isFilterVisible ? 'visible' : ''}`}>
        <h3 className='filter-title'>Filter By</h3>
        {filters.map(filter => (
          <div key={filter.id} className='filter-section'>
            <h4 className='filter-heading'>{filter.name}</h4>
            {filter.options.map(option => (
              <div key={option.value} className='filter-option'>
                <input
                  type='checkbox'
                  id={`${filter.id}-${option.value}`}
                  value={option.value}
                  onChange={() => handleCheckboxChange(filter.id, option.value)}
                  checked={
                    filter.id === 'remote'
                      ? selectedRemote.includes(option.value)
                      : filter.id === 'jobType'
                        ? selectedJobTypes.includes(option.value)
                        : selectedApplyDates.includes(option.value)
                  }
                />
                <label htmlFor={`${filter.id}-${option.value}`}>{option.label}</label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );


}

export default JobFilter;
