export const EducationForm = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className='col-name'>
        <label>Degree*</label>
        <input
          type="text"
          name="degree"
          value={formData.degree}
          onChange={handleChange}
          className='input-fields-about'
        />
      </div>

      <div className='row-name'>
        <div className='col-name'>
          <label>Start Year*</label>
          <input
            type="number"
            name="startYear"
            value={formData.startYear}
            onChange={handleChange}

            className='input-fields'
            placeholder='Start Year'
          />
        </div>

        <div className='col-name'>
          <label>End Year*</label>
          <input
            type="number"
            name="endYear"
            value={formData.endYear}
            onChange={handleChange}

            className='input-fields'
            placeholder='End Year'
          />
        </div>

        <div className='col-name'>
          <label>CGPA*</label>
          <input
            type="number"
            name="cgpa"
            value={formData.cgpa}
            onChange={handleChange}

            className='input-fields'
            placeholder='CGPA'
            max={10}
            min={1}
          />
        </div>
      </div>

      <div className='col-name mt-6'>
        <label>Specialization*</label>
        <input
          type="text"
          name="specialization"
          value={formData.specialization}
          onChange={handleChange}
          className='input-fields-about'
        />
      </div>

      <div className='col-name mt-6'>
        <label>Institute/College Name*</label>
        <input
          type="text"
          name="institute"
          value={formData.institute}
          onChange={handleChange}
          className='input-fields-about'
        />
      </div>

      <button type="submit" className='update-details-btn'>Add Education</button>
    </form>
  )
};