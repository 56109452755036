import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toaster } from 'react-hot-toast';
import { MantineProvider } from '@mantine/core';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from "react-redux";
import rootReducer from "./store/rootReducer"

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore({
  reducer: rootReducer,
});

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MantineProvider>
      <BrowserRouter>
        <App />
        <Toaster />
      </BrowserRouter>
    </MantineProvider>
  </Provider>

  // </React.StrictMode>
);
