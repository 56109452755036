export const ExperienceForm = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className='col-name'>
        <label>Designation*</label>
        <input
          type="text"
          name="designation"
          value={formData.designation} // Controlled input
          onChange={handleChange}
          required
          className='input-fields-about'
          placeholder='Enter your Designation'
        />
      </div>

      <div className='row-name'>
        <div className='col-name'>
          <label>Start Year*</label>
          <input
            type="number"
            name="startYear"
            value={formData.startYear} // Controlled input
            onChange={handleChange}
            required
            className='input-fields'
            placeholder='Enter the starting year'
          />
        </div>

        <div className='col-name'>
          <label>End Year*</label>
          <input
            type="number"
            name="endYear"
            value={formData.endYear} // Controlled input
            onChange={handleChange}
            required
            className='input-fields'
            placeholder='Enter the ending year'
          />
        </div>
      </div>

      <div className='col-name mt-6'>
        <label>Organization Name*</label>
        <input
          type="text"
          name="organizationName"
          value={formData.organizationName} // Controlled input
          onChange={handleChange}
          required
          className='input-fields-about'
          placeholder='Enter your organization name'
        />
      </div>

      <button type="submit" className='update-details-btn'>Add Experience</button>
    </form>
  )
};