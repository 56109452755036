/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useSelector } from 'react-redux';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({ pdf }) => {
  const { resume_file } = useSelector((state) => state.profile.user);
  const [showResume, setShowResume] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const pageWidth = windowWidth < 768 ? windowWidth * 0.9 : 768;

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowResume(false);
    }
  };

  useEffect(() => {
    // console.log(resume_file);
  }, [])
  return (
    <div>
      <button onClick={() => setShowResume(true)}>View Resume</button>
      {showResume && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-center transition-opacity duration-300" onClick={handleOverlayClick}>
          <div className="rounded-lg max-w-[100%] max-h-[90%] overflow-y-auto overflow-x-hidden relative transition-transform transform duration-300">
            <span className="absolute top-2 right-4 text-gray-500 text-2xl font-bold cursor-pointer hover:text-black z-20" onClick={() => setShowResume(false)}>&times;</span>

            <div className='w-[100%] overflow-hidden'>
              <Document file={pdf ? pdf : resume_file} onLoadSuccess={onDocumentLoadSuccess} className="pdf-viewer">
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageWidth}
                    className="pdf-page"
                  />
                ))}
              </Document>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
