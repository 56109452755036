import React, { useState } from 'react';
import "../../../CSS/contact.css";
import { Spinner } from "../../index"
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';
const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div className='flex items-center justify-center'>
      {loading && <Spinner />}
      <div className="contact-container">
        <span className="contact-big-circle"></span>
        <div className="contact-form-container">
          <ContactInfo />
          <ContactForm loading={loading} setLoading={setLoading} />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
