/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import axios from 'axios';
import "../../../CSS/EditProfile.css";
import { format } from 'date-fns'; // or import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../store';

const API_PORT = process.env.REACT_APP_API_PORT;

const BasicInformationForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profile);

  const [formData, setFormData] = useState({
    firstName: user.First_name,
    lastName: user.Last_name || '',
    email: user.Email,
    mobile: user.Mobile || '',
    gender: user.Gender || '',
    dob: user.Dob || '',
    bio: user.Bio || '',
    link1: user.Link1 || '',
    link2: user.Link2 || '',
    link3: user.Link3 || '',
    link4: user.Link4 || ''
  });

  const formatDate = (isoDate) => {
    if (!isoDate) return ''; // Ensure date is not undefined or null
    return format(new Date(isoDate), 'yyyy-MM-dd'); // or return moment(isoDate).format('MMMM D, YYYY');
  };


  // Function to handle changes in form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const resp = await axios.put(`${API_PORT}/update-user-data`, formData);
      if (resp.data.success) {
        const userDetails = {
          id: user.userID,
          type: JSON.stringify(user.User_Type),
        };
        // console.log(userDetails)
        dispatch(getUserDetails(userDetails))
        toast.success(resp.data.message);
      }
      else {
        toast.error(resp.data.message);
      }
    } catch (err) {
      // console.error('Error updating user data:', err);
      toast.error(err.response?.data?.message);
    }
  };

  return (
    <section className='box'>
      <p className="heading">Basic Information</p>
      <form onSubmit={handleSubmit}>
        <div className='row-name'>
          <div className='col-name'>
            <label>First Name*</label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} className='input-fields' placeholder='Enter your First Name' />
          </div>
          <div className='col-name'>
            <label>Last Name*</label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} className='input-fields' placeholder='Enter your Last Name' />
          </div>
        </div>

        <div className='row-name'>
          <div className='col-name'>
            <label>Email Id*</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} className='input-fields' disabled />
          </div>
          <div className='col-name'>
            <label>Mobile No.*</label>
            <input type="text" name="mobile" value={formData.mobile} onChange={handleChange} className='input-fields' placeholder='Enter your Contact Number' />
          </div>
        </div>

        <div className='row-name'>
          <div className='col-name'>
            <label>Gender*</label>
            <select name="gender" value={formData.gender} onChange={handleChange} className='input-fields'>
              <option value="">Not Selected</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className='col-name'>
            <label>Date of Birth*</label>
            <input type="date" name="dob" value={formatDate(formData.dob)} onChange={handleChange} className='input-fields' />
          </div>
        </div>

        <div className='mt-4'>
          <div className='row-name'>
            <div className='col-name'>
              <label>Social Links</label>
              <input type="text" name="link1" value={formData.link1} onChange={handleChange} className='input-fields' placeholder='Social Link 1' />
            </div>
            <div className='col-name md:mt-6'>
              <input type="text" name="link2" value={formData.link2} onChange={handleChange} className='input-fields' placeholder='Social Link 2' />
            </div>
          </div>

          <div className='row-name'>
            <div className='col-name'>
              <input type="text" name="link3" value={formData.link3} onChange={handleChange} className='input-fields' placeholder='Social Link 3' />
            </div>
            <div className='col-name'>
              <input type="text" name="link4" value={formData.link4} onChange={handleChange} className='input-fields' placeholder='Social Link 4' />
            </div>
          </div>

          <label className='mt-4'>Your Bio</label>
          <textarea name="bio" value={formData.bio} onChange={handleChange} rows='5' spellCheck='false' className='input-fields-about' placeholder='Describe about yourself'></textarea>

          <button type="submit" className='update-details-btn'>Update Details</button>
        </div>
      </form>
    </section>
  );
};

export default BasicInformationForm;
