import React from 'react';
import { deleteWorkExp } from '../../../store';
import { useDispatch } from 'react-redux';
import { MdDeleteForever } from 'react-icons/md';

const ExperienceCard = (WorkExperience) => {
  const dispatch = useDispatch()

  const handleDelete = async (srno) => {
    dispatch(deleteWorkExp({ srno }));
  };
  return (
    <div>
      {WorkExperience.map((exp, index) => (
        <div key={index} className='experience-item'>
          <div className='delete-icon' onClick={() => handleDelete(exp.srno, index)}>
            <MdDeleteForever size={20} />
          </div>
          <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Designation : {exp.designation}</p>
          <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{exp.organization}</p>
          <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>{exp.start_year} - {exp.end_year}</p>
        </div>
      ))}
    </div>
  );
};

export default ExperienceCard;