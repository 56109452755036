import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MasterImg from '../../../assets/Master.jpg';
import axios from "axios";
import toast from "react-hot-toast";
import { AllOpportunities } from '../../index';

const API_PORT = process.env.REACT_APP_API_PORT;

function AppliedOpportunities() {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [userEmail, setUserEmail] = useState('')

  useEffect(() => {
    setUserEmail(JSON.parse(localStorage.getItem("UserEmail")));
  }, [])

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      try {
        const response = await axios.get(`${API_PORT}/get-applied-jobs`, {
          params: { userEmail }
        });

        if (response.data.success) {
          const appliedJobs = response.data.jobs;
          setAppliedJobs(appliedJobs);

          const jobIds = appliedJobs.map((job) => job.jobId);
          const responses = await Promise.all(
            jobIds.map((id) =>
              axios.get(`${API_PORT}/get-post`, {
                params: { id }
              })
            )
          );

          const jobData = responses.map((response, index) => {
            const job = response.data.job;
            const status = appliedJobs[index].status;
            return { ...job, status };
          });

          setJobData(jobData);
          // console.log(jobData);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching applied jobs", error);
      }
    };

    if (userEmail) fetchAppliedJobs();
  }, [userEmail]);


  const navigate = useNavigate();

  if (appliedJobs.length === 0) {
    return (
      <>
        <div className="w-full mt-4 flex justify-center items-center">
          <div className="h-[500px] w-[600px] flex flex-col">
            <div>
              <img src={MasterImg} className="" alt="" />
            </div>
            <div className="flex justify-center">
              <p className="text-[18px]">You have not applied to any jobs</p>
            </div>
            <div className="flex justify-center">
              <p className="text-base">Start applying to jobs and get updates on the status of your job application</p>
            </div>
            <div className="flex justify-center">
              <button className="bg-primary-color rounded-lg mt-4 p-3" onClick={() => navigate('/opportunities')}>
                Search jobs
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="bg-gray-100 p-4 rounded-lg shadow-sm mb-4">
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold text-gray-800 pl-4">Job application status</p>
            <div className="flex items-center space-x-4">
              <p className="text-lg font-semibold text-gray-800">{appliedJobs.length}</p>
              <p className="text-lg font-semibold text-gray-800">Total Applies</p>
            </div>
          </div>
        </div>
        <div className="w-full p-4">
          <AllOpportunities jobs={jobData} />
        </div >
      </>
    );
  }
}

export default AppliedOpportunities;