import React from 'react';
import logo from "../../assets/TalentGro-RetinaLogo.svg";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import "../../CSS/Navbar.css";
import { toast } from "react-hot-toast";
import { BiLogOut } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Menu } from '@mantine/core';
import { FaUserCircle } from 'react-icons/fa';
import { MdDoneAll } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { NavbarLinks } from "../data/navbar-links";
import { HamburgerMenu } from '../index';
import { logout } from '../../store/slices/login/loginSlice';

const API_PORT = process.env.REACT_APP_API_PORT;
const Navbar = () => {
  const { isLoggedIn } = useSelector((state) => state.login);
  const { Image, profile_picture, User_Type, userID } = useSelector((state) => state.profile.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${API_PORT}/logout`, {}, { withCredentials: true });
      if (response.data.success) {
        localStorage.removeItem('UserEmail');
        localStorage.removeItem('id');
        localStorage.removeItem('UserType');
        localStorage.removeItem('token');
        dispatch(logout());
        toast.success("Logout Successfully");
        navigate('/login');
      } else {
        toast.error("Failed to logout");
      }
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error("Failed to logout");
    }
  };
  return (
    <div className='w-full flex justify-between items-center px-6 h-[70px] bg-[#FF5E05] border-b-2 z-20'>
      <div>
        <HamburgerMenu />
      </div>
      <NavLink to="/">
        <img src={logo} alt="Logo" loading="lazy" className='lg:h-[60px] h-[50px] w-auto transition duration-300 ease-in-out hover:scale-110' />
      </NavLink>

      <nav className='nav-small'>
        <ul>
          {NavbarLinks.map((route, index) => {
            const { href, title, userType } = route;
            if (userType === 0 || userType === User_Type)
              return (
                <li key={index}>
                  <NavLink
                    to={href}
                    className="navbar-navlink"
                  >
                    {title}
                  </NavLink>
                </li>
              );
            return null;
          }
          )}
        </ul>
      </nav>

      <div className="nav-container">
        {!isLoggedIn && (
          <NavLink to="/login">
            <button className="login-button">
              Login
            </button>
          </NavLink>
        )}

        {!isLoggedIn && (
          <NavLink to="/signup/student">
            <button className="signup-button">
              Sign Up
            </button>
          </NavLink>
        )}

        {isLoggedIn && (
          <div className='flex items-center justify-center md:mr-5 lg:mr-7 mr-2 relative'>
            <div>
              <button onClick={handleLogout}>
                <BiLogOut data-tooltip-id="my-logout-tooltip" size={30} color='white' />
                <ReactTooltip
                  id="my-logout-tooltip"
                  place="bottom"
                  effect="solid"
                  className="custom-tooltip"
                  content="Logout"
                />
              </button>
            </div>
          </div>
        )}

        {isLoggedIn && (
          <div className='md:pr-5 lg:pr-7 pr-1'>
            <Menu trigger="hover" openDelay={100} closeDelay={400}>
              <Menu.Target>
                <img src={profile_picture ? profile_picture : Image} className='avatar' alt="" />
              </Menu.Target>

              <Menu.Dropdown className='menu-dropdown'>
                {
                  User_Type === 1 ? <Link to={`/profile/student/${userID}`}>
                    <Menu.Item>
                      <div className='sub-menu'>
                        <FaUserCircle size={18} />
                        <span>My Profile</span>
                      </div>
                    </Menu.Item>
                  </Link>
                    : <Link to={`/profile/recruiter/${userID}`}>
                      <Menu.Item>
                        <div className='sub-menu'>
                          <FaUserCircle size={18} />
                          <span>My Profile</span>
                        </div>
                      </Menu.Item>
                    </Link>
                }
                {
                  User_Type === 1 ? <Link to="/applied">
                    <Menu.Item>
                      <div className='sub-menu'>
                        <MdDoneAll size={18} />
                        <span>Applied Jobs</span>
                      </div>
                    </Menu.Item>
                  </Link>
                    :
                    <Link to="/posted-job">
                      <Menu.Item>
                        <div className='sub-menu'>
                          <MdDoneAll size={18} />
                          <span>Posted Jobs</span>
                        </div>
                      </Menu.Item>
                    </Link>
                }
              </Menu.Dropdown>
            </Menu>
          </div>
        )}

      </div>
    </div>
  );
};

export default Navbar;
