import { createSlice } from "@reduxjs/toolkit"
import { getUserDetails } from "./profileActions"

const initialState = {
  user: {
    Image: "https://api.dicebear.com/5.x/initials/svg?seed=Talent%20Pool%20Por",
    profile_picture: null
  },
  loading: false,
  error: null,
}


const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setUser(state, value) {
      state.user = value.payload
    },
    setLoading(state, value) {
      state.loading = value.payload
    },
    updateProfilePicture(state, action) {
      state.user.profile_picture = action.payload;
    },
    updateResume(state, action) {
      state.user.resume_file = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("profile Slice-->", action.payload.results[0])
        state.user = action.payload.results[0]
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

  }
})

export const { setUser, setLoading, updateProfilePicture, updateResume } = profileSlice.actions

export default profileSlice.reducer
