import { useState } from "react"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { BiArrowBack } from "react-icons/bi"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Spinner } from "../../index"

function UpdatePassword() {
  const API_PORT = process.env.REACT_APP_API_PORT;
  const navigate = useNavigate()
  const location = useLocation()
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  })
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { password, confirmPassword } = formData

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  async function handleOnSubmit(e) {
    e.preventDefault()
    const token = location.pathname.split("/").at(-1);
    setLoading(true);
    try {
      const response = await axios.post(`${API_PORT}/reset-password`, { password, confirmPassword, token }, { withCredentials: true });
      console.log("RESET Password RESPONSE ... ", response.data);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success(response.data.message);
      navigate('/login');
    }
    catch (error) {
      console.log("RESET PASSWORD TOKEN Error", error);
      toast.error("Unable to reset password");
    }
    setLoading(false);
  }

  return (
    <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
      {loading && <Spinner />}
      <div className="max-w-[500px] p-4 lg:p-8">
        <h1 className="text-[1.875rem] font-semibold leading-[2.375rem]">
          Choose new password
        </h1>
        <p className="my-4 text-[1.125rem] leading-[1.625rem] text-gray-500">
          Almost done. Enter your new password and youre all set.
        </p>
        <form onSubmit={handleOnSubmit}>
          <label className="relative mt-3 block">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem]">
              New Password <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={handleOnChange}
              placeholder="Enter Password"
              className="w-full rounded-lg bg-[#dee2e6] p-3 text-[16px] leading-[24px] text-gray-700 shadow-[0_1px_0_0] shadow-white/50 placeholder:text-gray-400 focus:outline-none !pr-10"
            />
            <span
              onClick={() => setShowPassword((prev) => !prev)}
              className="absolute right-3 top-[42px] z-[10] cursor-pointer"
            >
              {showPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#e95014" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#e95014" />
              )}
            </span>
          </label>
          <label className="relative mt-3 block">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem]">
              Confirm New Password <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleOnChange}
              placeholder="Confirm Password"
              className="w-full rounded-lg bg-[#dee2e6] p-3 text-[16px] leading-[24px] text-gray-700 shadow-[0_1px_0_0] shadow-white/50 placeholder:text-gray-400 focus:outline-none !pr-10"
            />
            <span
              onClick={() => setShowConfirmPassword((prev) => !prev)}
              className="absolute right-3 top-[42px] z-[10] cursor-pointer"
            >
              {showConfirmPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#e95014" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#e95014" />
              )}
            </span>
          </label>

          <button
            type="submit"
            className="mt-6 login-signin-button"
          >
            Reset Password
          </button>
        </form>
        <div className="mt-6 flex items-center justify-between">
          <Link to="/login" className="no-underline">
            <p className="flex items-center gap-x-2 text-gray-500">
              <BiArrowBack /> Back To Login
            </p>
          </Link>
        </div>
      </div>

    </div>
  )
}
export default UpdatePassword;