import React, { useState } from "react"
import { BiArrowBack } from "react-icons/bi"
import { Link } from "react-router-dom"
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Spinner } from "../../index"

function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false);


  const API_PORT = process.env.REACT_APP_API_PORT;

  async function handleOnSubmit(e) {
    e.preventDefault()
    setLoading(true);

    try {
      const response = await axios.post(`${API_PORT}/reset-password-token`, { email }, { withCredentials: true });

      console.log("RESET PASSWORD TOKEN RESPONSE....", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success(response.data.message);
      setEmailSent(true);
    }
    catch (error) {
      console.log("RESET PASSWORD TOKEN Error", error);
      toast.error("Failed to send email for resetting password");
    }
    setLoading(false);
  }

  return (
    <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
      {loading && <Spinner />}
      <div className="max-w-[500px] p-4 lg:p-8">
        <h1 className="text-[1.875rem] font-semibold leading-[2.375rem]">
          {!emailSent ? "Reset your password" : "Check email"}
        </h1>
        <p className="my-4 text-[1.125rem] leading-[1.625rem] text-slate-700">
          {!emailSent
            ? "We’ll send a password reset link to this email if it matches an existing Talent Pool account."
            : `We have sent the reset email to ${email}`}
        </p>
        <form onSubmit={handleOnSubmit}>
          {!emailSent && (
            <label className="w-full">
              <p className="mb-1 text-[0.875rem] leading-[1.375rem]">
                Email Address <sup className="text-pink-200">*</sup>
              </p>
              <input
                required
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
                className="w-full rounded-lg bg-[#dee2e6] p-3 text-[16px] leading-[24px] text-gray-700 shadow-[0_1px_0_0] shadow-white/50 placeholder:text-gray-400 focus:outline-none"
              />
            </label>
          )}
          <button
            type="submit"
            className="mt-6 login-signin-button"
          >
            {!emailSent ? "Sumbit" : "Resend Email"}
          </button>
        </form>
        <div className="mt-6 flex items-center justify-between">
          <Link to="/login" className="no-underline">
            <p className="flex items-center gap-x-2 text-gray-500">
              <BiArrowBack /> Back To Login
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword