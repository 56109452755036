// src/components/Login.js
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import '../../../CSS/Login.css';
import LoginImage from '../../../assets/login_Image.png';
import { FcGoogle } from 'react-icons/fc';
import { Spinner } from "../../index"
import { useSelector, useDispatch } from 'react-redux';
import {
  loginUser,
  loginWithGoogle,
  getUserDetails,
  fetchAchievements,
  fetchCertifications,
  fetchEducationData,
  fetchProjectData,
  fetchWorkExpData
} from '../../../store/index';


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, userEmail, token, userType, userId } = useSelector((state) => state.login);

  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
  });

  const changeHandler = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const user = {
      email: formData.email,
      password: formData.password,
    };
    try {
      setLoading(true);
      const result = await dispatch(loginUser(user)).unwrap();

      if (result) {
        const userDetails = {
          id: result.userID,
          type: JSON.stringify(result.userType),
        };
        await dispatch(getUserDetails(userDetails)).unwrap();
        navigate('/');
        if (result.userType === 1) {
          await dispatch(fetchAchievements(formData.email)).unwrap();
          await dispatch(fetchCertifications(formData.email)).unwrap();
          await dispatch(fetchEducationData(formData.email)).unwrap();
          await dispatch(fetchProjectData(formData.email)).unwrap();
          await dispatch(fetchWorkExpData(formData.email)).unwrap();
        }
      }
    } catch (error) {
      console.error("Login failed", error);
    }
    finally {
      setLoading(false);
    }
  };

  const signInWithGoogleHandler = async () => {
    setLoading(true);
    dispatch(loginWithGoogle()).unwrap()
      .then(async (result) => {
        if (result.success) {
          const userDetails = {
            id: result.userID,
            type: JSON.stringify(result.userType),
          };

          await dispatch(getUserDetails(userDetails)).unwrap();
          navigate('/');
          if (result.userType === 1) {
            await dispatch(fetchAchievements(formData.email)).unwrap();
            await dispatch(fetchCertifications(formData.email)).unwrap();
            await dispatch(fetchEducationData(formData.email)).unwrap();
            await dispatch(fetchProjectData(formData.email)).unwrap();
            await dispatch(fetchWorkExpData(formData.email)).unwrap();
          }
        }
      })
      .catch((error) => {
        console.error("Login with Google failed", error);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem('UserEmail', JSON.stringify(userEmail));
      localStorage.setItem('id', JSON.stringify(userId));
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('UserType', userType);
    }
  }, [dispatch, isLoggedIn, token, userEmail, userType, userId]);

  return (
    <div className="login-w-full login-h-full relative z-1">
      {loading && <Spinner />}
      <div className="login-flex-center">
        <div className="login-form-container">
          <div className="login-form-content">
            <h1 className="login-form-title">Sign in to your account</h1>

            <form className="login-form" onSubmit={submitHandler}>
              <div className="login-label">
                <label className="login-label-text">
                  <p>
                    Your email
                    <sup className="login-text-pink-600">*</sup>
                  </p>
                  <input
                    onChange={changeHandler}
                    type="email"
                    name="email"
                    value={formData.email}
                    className="login-input"
                    placeholder="John@gmail.com"
                    required
                  />
                </label>
              </div>

              <div className="login-label login-relative">
                <label htmlFor="password" className="login-label-text relative">
                  <p>
                    Password
                    <sup className="login-text-pink-600">*</sup>
                  </p>
                  <input
                    onChange={changeHandler}
                    type={showPassword ? 'text' : 'password'}
                    value={formData.password}
                    name="password"
                    placeholder="••••••••"
                    className="login-input"
                    required
                  />
                  <span onClick={() => setShowPassword(!showPassword)} className="login-eye-icon">
                    {showPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#e95014" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#e95014" />
                    )}
                  </span>
                </label>
                <NavLink to="/forgot-password">
                  <p className="mt-1 ml-auto max-w-max text-xs text-blue-600">
                    Forgot Password
                  </p>
                </NavLink>
              </div>

              <button type="submit" className="login-signin-button" disabled={loading}>
                Sign in
              </button>

              <div className="flex w-full items-center justify-center my-4 gap-x-2">
                <div className="h-[1px] w-full bg-primary-color"></div>
                <p className="font-medium text-primary-color leading-[1.375rem] mb-0">OR</p>
                <div className="h-[1px] w-full bg-primary-color"></div>
              </div>

              <button
                type="button"
                onClick={signInWithGoogleHandler}
                className="button-signup-google"
                disabled={loading}
              >
                <FcGoogle size={20} />
                <span>Continue with Google</span>
              </button>

              <p className="login-signup-link">
                Don’t have an account yet?
                <NavLink to="/signup"> Sign up</NavLink>
              </p>
            </form>
          </div>
        </div>
        <div>
          <img src={LoginImage} alt="Login" className="login-img" />
        </div>
      </div>
    </div>
  );
};

export default Login;
