/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import "../../../CSS/Profile.css"
import { Spinner, Footer, PdfViewer } from '../../index'
import { updateProfilePicture, updateResume } from '../../../store/slices/studentSlices/basicInformation/profileSlice';

const API_PORT = process.env.REACT_APP_API_PORT;

const Profile = () => {
  const { ProfileUrl } = useParams();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useState({
    userEmail: '',
    userName: '',
    coverImage: null,
    ProfilePic: null,
    userResume: null,
    AboutMe: '',
    educationData: [],
    workExperienceData: [],
    projectData: [],
    achievementData: [],
    certificateData: [],
  });

  const inputRef = useRef(null);
  const input_img = useRef(null);

  const {
    First_name, Last_name, Email, userID, Image,
    profile_picture, Bio, resume_file, achievements,
    certificates, education, project, WorkExperience
  } = useSelector((state) => ({
    ...state.profile.user,
    achievements: state.acheivementsAndCertificates.achievements,
    certificates: state.acheivementsAndCertificates.certificates,
    education: state.educations.education,
    project: state.projects.project,
    WorkExperience: state.experiences.WorkExperience,
  }));

  const fetchUserProfileData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_PORT}/fetch-user-data`, {
        params: { id: ProfileUrl }
      });

      if (response.data.success) {
        const data = response.data.results[0];
        // console.log(data)
        setUserInfo({
          userEmail: data.Email,
          userName: `${data.First_name} ${data.Last_name}`,
          coverImage: data?.profile_picture || null,
          ProfilePic: data?.Image || null,
          userResume: data?.resume_file || null,
          AboutMe: data?.Bio || '',
        });
        await fetchEducationData(data.Email);
        await fetchWorkExperience(data.Email);
        await fetchProjects(data.Email);
        await fetchAchievements(data.Email);
        await fetchCertifications(data.Email);
      } else {
        // console.error('Error fetching user profile data:', response.data.message);
        toast.error(response.data.message);
      }
    } catch (error) {
      // console.error('Error fetching user profile data:', error);
      toast.error(error.response?.data?.message);
    }
    finally {
      setLoading(false);
    }
  };

  const fetchEducationData = async (email) => {
    try {
      const response = await fetch(`${API_PORT}/get-education`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': email
        }
      });

      const data = await response.json();

      if (data.success) {
        setUserInfo((prev) => ({ ...prev, educationData: data.education }));
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchWorkExperience = async (email) => {
    try {
      const response = await axios.get(`${API_PORT}/get-workexp?email=${email}`);
      if (response.data.success) {
        setUserInfo((prev) => ({ ...prev, workExperienceData: response.data.workExperienceDetails }))
      } else {
        console.error('Error fetching work experience:', response.data.message);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Error fetching work experience:', error.response.data.message);
      } else if (error.request) {
        // Request was made but no response was received
        console.error('No response received:', error.request);
      } else {
        // Something else happened
        console.error('Error:', error.message);
      }
    }
  };

  const fetchProjects = async (email) => {
    try {
      const response = await axios.get(`${API_PORT}/get-projects?email=${email}`);
      if (response.data.success) {
        setUserInfo((prev) => ({ ...prev, projectData: response.data.projects }));
      } else {
        console.error('Error fetching projects:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchAchievements = async (email) => {
    try {
      const response = await axios.get(`${API_PORT}/get-achievements?email=${email}`);
      if (response.data.success) {
        setUserInfo((prev) => ({ ...prev, achievementData: response.data.achievements }));
      } else {
        console.error('Error fetching achievements:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching achievements:', error);
    }
  };

  const fetchCertifications = async (email) => {
    try {
      const response = await axios.get(`${API_PORT}/get-certifications?email=${email}`);
      if (response.data.success) {
        setUserInfo((prev) => ({ ...prev, certificateData: response.data.certifications }));
      } else {
        console.error('Error fetching certifications:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching certifications:', error);
    }
  };

  useEffect(() => {
    if (ProfileUrl !== userID) {
      fetchUserProfileData();
      // console.log(userInfo)
    }
  }, [ProfileUrl, userID])

  const triggerFileInput = () => inputRef.current?.click();

  const openChooseFile = () => input_img.current?.click();

  const handleChangeCover = async (event) => {
    const selectedFile = event.target.files[0];
    const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
    const maxSize = 2 * 1024 * 1024;
    if (selectedFile) {
      if (!ALLOWED_TYPES.includes(selectedFile.type)) {
        toast.error('Invalid file type. Only PNG, JPEG, and JPG files are allowed.');
        return;
      }
      if (selectedFile.size > maxSize) {
        toast.error(`File size exceeds the ${maxSize / (1024 * 1024)}MB limit. Please upload a smaller file.`);
        return false;
      }

      // Prepare form data for file upload
      const formData = new FormData();
      formData.append('profile_picture', selectedFile);
      formData.append('email', Email);

      try {
        setLoading(true);
        const response = await axios.post(`${API_PORT}/upload-profile-picture`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.success) {
          toast.success(response.data.message);
          dispatch(updateProfilePicture(URL.createObjectURL(selectedFile)))
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Error uploading profile picture';
        toast.error(errorMessage);
      }
      setLoading(false);
    }
  };

  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    // Check if file exists
    if (file) {
      // Check if file is a PDF
      if (file.type !== 'application/pdf') {
        toast.error('Please upload a valid PDF file');
        return;
      }

      // Check if file size is 2 MB or less
      if (file.size > maxSize) { // 2 MB in bytes
        toast.error(`File size exceeds the ${maxSize / (1024 * 1024)}MB limit. Please upload a smaller file.`);
        return false;
      }

      const formData = new FormData();
      formData.append('resume', file);
      formData.append('email', Email);

      try {
        setLoading(true);
        const response = await axios.post(`${API_PORT}/upload-resume`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.data.success) {
          dispatch(updateResume(URL.createObjectURL(file)))
          toast.success('Resume uploaded successfully');
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error uploading resume:', error);
        toast.error('Error uploading resume');
      }
    } else {
      toast.error('No file selected');
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className='profileDashboard'>
        <h1 className='profiletitle'>Profile</h1>
        <div className='box user-container mt-0'>
          <div className='user-avatar'>
            <span className="userProfileImage">
              <img src={
                userInfo.coverImage
                  ? userInfo.coverImage
                  : userInfo.ProfilePic
                    ? userInfo.ProfilePic
                    : profile_picture
                      ? profile_picture
                      : Image
              } alt="User Profile" className='profileImage' />

              {ProfileUrl === userID &&
                <button onClick={openChooseFile} position="absolute" top={4} right={4} variant="ghost">
                  <svg width="1.2em" fill="currentColor" viewBox="0 0 20 20" className='profile-change-icon'>
                    <path fillRule="evenodd" clipRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" />
                  </svg>
                  <input ref={input_img} type="file" onChange={handleChangeCover} hidden />
                </button>
              }
            </span>
          </div>
          <div className='profile-details'>
            <span className='user-name'>{userInfo.userName || First_name} {Last_name}</span>
            <span className='user-email'>{userInfo.userEmail || Email}</span>
          </div>


          <div className='profile-info'>
            {
              (userInfo.userResume || resume_file) &&
              <div className='mb-2'>
                {
                  userInfo.userResume ? <PdfViewer pdf={userInfo.userResume} /> : <PdfViewer />
                }
              </div>
            }
            <input
              type="file"
              accept=".pdf"
              className='resume-upload'
              ref={inputRef}
              onChange={handleResumeUpload}
              style={{ display: 'none' }}
            />
            {ProfileUrl === userID &&
              <div className='buttons'>
                <button className='profile-button' onClick={triggerFileInput}>
                  Update Resume
                </button>
                <NavLink to="/edit-profile" className='profile-button text-center no-underline'>Edit Profile</NavLink>
              </div>}
          </div>
        </div>

        <div className='mt-5'>
          {(Bio || userInfo.AboutMe) &&
            <section className="box">
              <p className="heading">ABOUT ME</p>
              <p className="about-desc">{userInfo.AboutMe || Bio}</p>
            </section>}


          {(userInfo.workExperienceData?.length !== 0 || WorkExperience?.length !== 0) &&
            <section className="box">
              <p className="heading">WORK EXPERIENCE</p>
              <div className='education-list'>
                {(userInfo.workExperienceData?.length !== 0 ? userInfo.workExperienceData : WorkExperience)
                  ?.map((exp, index) => (
                    <div key={index} className='experience-item'>
                      <p className='text-[1.2rem] font-semibold leading-4 text-[#002b36]'> <span className='no-underline font-bold'>Designation :</span>  {exp.designation}</p>
                      <p className='text-[1rem] leading-3 text-[#002b36]'> <span className='no-underline font-bold'>Organization :</span>  {exp.organizationName}</p>
                      <p className='text-[1rem] leading-3 text-[#002b36]'>{exp.start_year} - {exp.end_year}</p>
                    </div>
                  )
                  )}
              </div>
            </section>
          }

          {(userInfo.educationData?.length !== 0 || education?.length !== 0) && <section className="box">
            <p className="heading">EDUCATION</p>
            <div className='education-list'>
              {(userInfo.educationData?.length !== 0 ? userInfo.educationData : education)?.map((edu, index) => (
                <div key={index} className='education-item'>
                  <p className='text-[1.2rem] font-semibold leading-4 text-[#002b36]'>Institute: {edu.Institute}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>Degree:</span> {edu.Degree}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>Start Year:</span> {edu.Start_year}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>End Year:</span> {edu.End_year}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>CGPA:</span> {edu.CGPA}</p>
                </div>
              ))}
            </div>
          </section>}

          {(userInfo.projectData?.length !== 0 || project?.length !== 0) && <section className="box">
            <p className="heading">PROJECTS</p>
            <div className='education-list'>
              {(userInfo.projectData?.length !== 0 ? userInfo.projectData : project)?.map((project, index) => (
                <div key={index} className='experience-item'>
                  <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Title: {project.Title}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-serif'><span className='font-bold'>Description:</span> {project.Description}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-medium'><span className='font-bold'>Link:</span> <a href={project.ProjectLink} target='_blank' rel="noreferrer" className='no-underline'>{project.ProjectLink}</a></p>
                </div>
              ))}
            </div>
          </section>}

          {(userInfo.achievementData?.length !== 0 || achievements?.length !== 0) && <section className="box">
            <p className="heading">ACHEIVEMENTS</p>
            <div className='education-list'>
              {(userInfo.achievementData?.length !== 0 ? userInfo.achievementData : achievements)?.map((achievement, index) => (
                <div key={index} className='experience-item'>
                  <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'><span className='font-bold'>Name:</span>  {achievement.A_C_Name}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-serif'><span className='font-bold'>Description:</span> {achievement.Description}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>
                    <span className='font-bold'>Link: </span>
                    <a href={achievement.Link} target='_blank' rel="noreferrer" className='no-underline'>{achievement.Link}</a></p>
                </div>
              ))}
            </div>
          </section>}

          {(userInfo.certificateData?.length !== 0 || certificates?.length !== 0) && <section className="box">
            <p className="heading">CERTIFICATES</p>
            <div className='education-list'>
              {(userInfo.certificateData?.length !== 0 ? userInfo.certificateData : certificates)?.map((certification, index) => (
                <div key={index} className='experience-item'>
                  <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'><span className='no-underline font-bold'>Name:</span> {certification.A_C_Name}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-serif'><span className='font-bold'>Description:</span> {certification.Description}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-medium'><span className='font-bold'>Link: </span>
                    <a href={certification.Link} target='_blank' rel="noreferrer" className='no-underline'>{certification.Link}</a></p>
                </div>
              ))}
            </div>
          </section>}
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default Profile;
