import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { fetchProjectData } from '../../../store';
import ProjectCard from './ProjectCard';
import { ProjectForm } from './ProjectForm';
const API_PORT = process.env.REACT_APP_API_PORT;

const Projects = () => {
  const dispatch = useDispatch()
  const { Email } = useSelector((state) => state.profile.user);
  const { project } = useSelector((state) => state.projects)

  const [projectData, setProjectData] = useState({
    title: '',
    link: '',
    description: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_PORT}/add-project`, {
        ...projectData,
        email: Email,
      });

      if (response.data.success) {
        dispatch(fetchProjectData(Email))
        setProjectData({ title: '', link: '', description: '' });
        setFormVisible(false);
      } else {
        console.error('Failed to add project:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };

  return (
    <section className='box'>
      <p className="heading">Projects</p>
      {ProjectCard(project)}

      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New
        </div>
      )}
      {isFormVisible && (
        <ProjectForm
          formData={projectData}
          handleChange={(e) => handleChange(e)}
          handleSubmit={(e) => handleSubmit(e)}
        />
      )}
    </section>
  );
};

export default Projects;
