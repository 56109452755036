import { MdDeleteForever } from 'react-icons/md';
import FormatDate from "../../../Utils/FormatDate";
import { useDispatch } from 'react-redux';
import { deleteAchievement, deleteCertificate } from "../../../store/index"

const AcheivementCard = (achievements, activeTab) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    if (activeTab === 'achievements') {
      dispatch(deleteAchievement({ id }));
    }
    else if (activeTab === 'certifications') {
      dispatch(deleteCertificate({ id }));
    }
  };

  if (achievements.length === 0) {
    return <p>{activeTab === "achievements" ? "No achievements found." : " No certificate found."}</p>;
  }

  return (
    <div>
      {achievements && achievements.map((item, index) => (
        <div key={index} className='experience-item'>
          <div className='delete-icon' onClick={() => handleDelete(item.ID)}>
            <MdDeleteForever size={20} />
          </div>
          <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Name: {item.A_C_Name}</p>
          <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{item.Description}</p>
          <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{FormatDate(item.Date)}</p>
          <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>{item.Link}</p>
        </div>
      ))}
    </div>
  )
};

export default AcheivementCard;
