import { FaLinkedin, FaSquareInstagram, FaLocationDot } from "react-icons/fa6";
import { FaFacebookSquare, FaPhoneAlt } from "react-icons/fa";
import { SiGmail } from 'react-icons/si';

const ContactInfo = () => (
  <div className="contact-info">
    <h3 className="contact-title">Let&apos;s get in touch</h3>
    <p className="contact-text">Feel free to send me a message using the contact form below.</p>
    <div className="contact-information">
      <FaLocationDot className="contact-icon" size={25} />
      <p>Savitry Enclave, 40 A/4, VIP Rd, Zirakpur, Punjab 140603</p>
    </div>
    <div className="contact-information">
      <a href="mailto:Talentgroglobal@gmail.com">
        <SiGmail className="contact-icon" size={25} />
        <p>Talentgroglobal@gmail.com</p>
      </a>
    </div>
    <div className="contact-information">
      <a href="tel:+917986667827">
        <FaPhoneAlt className="contact-icon" size={25} />
        <p>+91-7986667827</p>
      </a>
    </div>
    <div className="contact-social-media">
      <p>Connect with us:</p>
      <div className="contact-social-icons">
        <a href="https://www.facebook.com/TalentGroGlobal" target="_blank" rel="noopener noreferrer">
          <FaFacebookSquare />
        </a>
        <a href="https://www.instagram.com/talentgro_global/" target="_blank" rel="noopener noreferrer">
          <FaSquareInstagram />
        </a>
        <a href="https://www.linkedin.com/company/talentgro-global-pvt-ltd/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </div>
    </div>
  </div>
);

export default ContactInfo;
