import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { auth, provider, signInWithPopup } from '../../../components/core/Auth/firebase';


const API_PORT = process.env.REACT_APP_API_PORT;

// Async thunk for email/password login
export const loginUser = createAsyncThunk(
  'login/loginUser',
  async (userCredentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_PORT}/login`, userCredentials, { withCredentials: true });
      const data = response.data;

      if (!data.success) {
        return rejectWithValue(data.message || 'Invalid email or password');
      }

      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || 'Login failed');
    }
  }
);

// Async thunk for Google login
export const loginWithGoogle = createAsyncThunk(
  'login/loginWithGoogle',
  async (_, { rejectWithValue }) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken();
      const userEmail = result.user.email || '';

      // Send token to backend
      const response = await axios.post(`${API_PORT}/googlelogin`, { tokenId: token }, { withCredentials: true });
      const data = response.data;

      if (!data.success) {
        return rejectWithValue(data.message || 'Google sign-in was unsuccessful');
      }

      return { ...data, email: userEmail };
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || 'Google sign-in failed');
    }
  }
);