import { createSlice } from "@reduxjs/toolkit";
import { deleteWorkExp, fetchWorkExpData } from "./experienceActions";

const initialState = {
  WorkExperience: [],
  error: null,
}

const workExperienceSlice = createSlice({
  name: "WorkExperience",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkExpData.fulfilled, (state, action) => {
        state.WorkExperience = action.payload;
        state.error = null;
      })
      .addCase(fetchWorkExpData.rejected, (state, action) => {
        state.error = action.payload;
      })

      // delete WorkExperience
      .addCase(deleteWorkExp.fulfilled, (state, action) => {
        state.WorkExperience = state.WorkExperience.filter(item => item.srno !== action.payload);
        state.error = null;
      })
      .addCase(deleteWorkExp.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
})

export default workExperienceSlice.reducer;